<template>
  <div>
    <template v-if="dashboardType === 'default'">
      <default-dashboard
        :unitID="unitID"
        :description="description"
        :valid-analogs="validAnalogs"
        :valid-digitals="validDigitals"
        :valid-temperatures="validTemperatures"
        :configured-analogs="configuredAnalogs"
        :power-state="powerState"
      />
    </template>
    <template v-else>
      <dynamic-dashboard
        :unitID="unitID"
        :description="description"
        :valid-analogs="validAnalogs"
        :valid-digitals="validDigitals"
        :valid-tables="validTables"
        :valid-time-series="validTimeSeries"
        :valid-apps="validApps"
        :configured-analogs="configuredAnalogs"
        :power-state="powerState"
        :show-unit-status="showUnitStatus"
        :show-network-status="showNetworkStatus"
        :show-diag="showDiag"
        :diag-columns="diagColumns"
        :show-events="showEvents"
      />
    </template>
  </div>
</template>
<script>
import { meshDb } from "@/firebase";
import DefaultDashboard from "./Templates/Default.vue";
import DynamicDashboard from "./Templates/Dynamic.vue";

export default {
  components: {
    DefaultDashboard,
    DynamicDashboard
  },
  data() {
    return {
      unitID: null,
      dashboardType: null,
      template: null,
      validAnalogs: [],
      validDigitals: [],
      validTemperatures: [],
      validTables: [],
      validApps: [],
      validTimeSeries: [],
      configuredAnalogs: [],
      description: {},
      powerState: {},
      alertTime: 86400 * 1000 * 7, // 1 day in milliseconds
      configObserver: null,
      powerStateObserver: null,
      showUnitStatus: false,
      showNetworkStatus: false,
      showDiag: false,
      diagColumns: [
        {name:"Time Stamp", field:"MessageUTC"},
        {name:"Name", field:"Payload.name"},
        {name:"Source", field:"Payload.source"},
        {name:"State", field:"Payload.state"},
        {name:"Description", field:"Payload.description"},
      ],
      showEvents: false
    }
  },
  methods: {
    /**
     * Get the unit config details
     *
     * @returns {Promise<void>}
     */
    async getConfig() {
      const database = this.$store.getters.userDatabase;
      try { this.configObserver && this.configObserver() } catch (e) {}
      this.configObserver = await meshDb
        .collection(database)
        .doc(this.unitID)
        .get()
        .then(this.processConfig);
    },

    /**
     * Process the unit config
     *
     * @param unitDoc
     * @returns {Promise<void>}
     */
    async processConfig(unitDoc) {
      const templateID = unitDoc.data().TemplateID || null
      if (!templateID) {
        // If no template ID set, flag as default template
        this.dashboardType = 'default'
        const config = unitDoc.data().description;
        if (!config) { return; }
        this.description = config;
        config.forEach(input => {
          for (let property in input) {
            if (property.includes('digitalInput')) {
              this.validDigitals.push(input);
            } else if (property.includes('analogInput')) {
              this.validAnalogs.push(input);
            } else if (property.includes('temperatureSensor')) {
              this.validTemperatures.push(input);
            }
          }
        });
      } else {
        // If template ID set, flag as dynamic template
        this.dashboardType = 'dynamic'

        // Fetch the dynamic template
        const template = (await meshDb
          .collection('templates')
          .doc(templateID)
          .get()).data() || null;

        if (!template) { return; }
        const config = template.description;
        config.forEach(input => {
          switch (input.type) {
            case 'currentStateCard':
              // Use the DigitalInput component for state cards
              const di = {}
              di[input.source] = {
                description: input.description,
                units: input.units || null,
                specifications: input.specifications || [{field: "currentState", name: "Enabled"}]
              }
              this.validDigitals.push(di)
              break;
            case 'gauge':
              // Use the AnalogInput component for gauges
              const ai = {}
              ai[input.source] = {
                description: input.description,
                units: input.units || null,
                field: input.field || "value"
              }
              this.validAnalogs.push(ai)
              break;
            case 'timeseries':
              // Use the TimeSeries component for timeseries
              const ts = {}
              ts[input.source] = {
                description: input.description,
                chartSeries: input.chartSeries || [{name: input.units, field: "value", color: "#ff0000"}]
              }
              this.validTimeSeries.push(ts)
              break;
            case 'app':
              // For apps simply push to array, will process in dynamic dashboard component
              this.validApps.push(input)
              break;
            case 'table':
              // For tables simply push to array, will process in dynamic dashboard component
              this.validTables.push(input)
              break;
            case 'unitStatus':
              this.showUnitStatus = input.show;
              break;
            case 'networkStatus':
              this.showNetworkStatus = input.show;
              break;
            case 'diagnostics':
              this.showDiag = input.show;
              this.diagColumns = input.specifications;
              break;
            case 'eventLog':
              this.showEvents = input.show;
              break;
          }
        });
      }
    },

    /**
     * Get the power state (if one is available)
     *
     * @returns {Promise<void>}
     */
    async getPowerState() {
      const database = this.$store.getters.userDatabase;
      try { this.powerStateObserver && this.powerStateObserver() } catch (e) {}
      this.powerStateObserver = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("powerState")
        .orderBy("MessageUTC", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              let state
              try {
                // Attempt to get the state
                state = change.doc.data().Payload.boolIgnition;
              } catch (e) {
                return
              }

              // Setup variables
              let title = `Running: ${state}`;
              let subTitle = 'Tractor Ignition State:';
              let type = state === 'True' ? "success" : "danger";
              let date = new Date(change.doc.data().MessageUTC + "Z");
              let footer = `<i class="tim-icons icon-calendar-60"></i></i> Updated: ${date.toLocaleString()}`;
              const now = Date.now();

              // See if we need to flag as warning
              if ((now - date) > this.alertTime) {
                type = "danger";
                title = "!WARNING! " + title.toString();
                const days = this.alertTime / 1000 / 86400;
                footer = `<b><i class="tim-icons icon-calendar-60"></i></i> No update within ${days} days!<br>Updated: ${date.toLocaleString()}</b>`;
              }

              // Update the power state
              this.powerState = {
                title, subTitle, type, footer, date
              }
            }
          });
        });
    },
  },
  created() {
    this.unitID = this.$route.params.unitID
  },
  mounted() {
    this.getConfig();
    this.getPowerState();
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.configObserver && this.configObserver() } catch (e) {}
    try { this.powerStateObserver && this.powerStateObserver() } catch (e) {}
  }
};
</script>
<style></style>
