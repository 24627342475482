<template>
  <card class="stacked-form" title="Data Export">
    <!-- <h4 slot="header" class="card-title">Data Export</h4> -->
    <form @submit.prevent>
      <div>
        <base-input>
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </base-input>
        <div align="center">
          <div class="">
            <base-button
              native-type="submit"
              type="primary"
              class="d-block mt-3"
              @click="toggleText"
            >
              {{ buttonText }}
            </base-button>
            <base-button
              native-type="button"
              type="link"
              :loading="generating"
              @click="$router.push(`/dashboard/${unitID}/events`)"
            >
              Events Table
            </base-button>
          </div>
        </div>
      </div>
    </form>
  </card>
</template>
<script>
import { meshDb } from "@/firebase";
import {DatePicker} from "element-ui";
import swal from "sweetalert2";
import exportMixin from "./Mixins/exportMixin";

export default {
  components: {
    [DatePicker.name]: DatePicker
  },
  mixins: [exportMixin],
  data() {
    return {
      observer: null,
      dateRange: [],
      downloadFilename: null,
      downloadPath: null,
      buttonState: "export",
      buttonText: "Export",
      generating: false,
      pickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      }
    };
  },
  props: {
    unitID: null
  },
  methods: {
    async exportData() {
      if (this.dateRange.length < 2) {
        swal.fire({
          text: "Please select a start date.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return;
      }
      let startDate = new Date(this.dateRange[0]);
      let endDate = new Date(this.dateRange[1]);
      let numberOfDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

      if (numberOfDays > 100) {
        swal.fire({
          text: "Please select a date range that contains 100 days or less.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return;
      }

      this.buttonState = "generate";
      this.buttonText = "Generating...";

      startDate.setMilliseconds(0);
      let startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/.000Z/, "");
      let endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/.000Z/, "");

      this.$emit('loading', true);
      this.generating = true;

      const csv = await this.generateCsv(startUTC, endUTC)

      this.$emit('loading', false);
      this.generating = false;

      this.buttonState = "export";
      this.buttonText = "Export";

      if (csv === false) {
        swal.fire({
          text: "No records were found for the selected date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return;
      }

      swal.fire({
        text: "CSV generated, downloading now...",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-ok btn-fill"
        }
      });
    },

    toggleText() {
      if (this.buttonState === "export") {
        this.exportData();
      }
    },

    async generateCsv(startUTC, endUTC) {
      const database = this.$store.getters.userDatabase

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("events")
        .orderBy("timestamp", "desc")
        .where("timestamp", ">=", startUTC)
        .where("timestamp", "<=", endUTC)
        .get();

      const fileName = startUTC.split(" ")[0] +
        "__" + endUTC.split(" ")[0] +
        ".csv";

      if (this.observer.empty) {
        console.log("No records were found for that date range!");
        return false;
      }

      this.dataToExport = [];
      this.observer.forEach((doc) => this.dataToExport.push(doc.data()));

      return this.exportDataCSV(fileName);
    }
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/dashboard/custom/_variables.scss";

.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 5%;
  color: $primary;
}
</style>
