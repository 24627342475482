<template>
  <card class="card-amchart" v-waypoint="{ active, callback: isInViewport }">
    <template slot="header">
      <h5 class="card-category">{{ analogInput }}</h5>
      <h3 class="card-title">
        <i class="tim-icons icon-chart-bar-32"></i> {{ title }}
      </h3>
      <small v-if="nrtime" class="text-muted">
        Displaying results for the last 24hr
      </small>
      <small v-else class="">
        Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
      </small>
    </template>
    <div
      class="chart-area"
      ref="chartdiv"
      componentKey="componentKey"
    ></div>
  </card>
</template>

<script>
import moment from 'moment'
import colors from "@/config.js"

import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import amc5mixin from '@/pages/Charts/Mixins/amc5mixin'

import {meshDb} from "@/firebase"

export default {
  mixins: [amc5mixin],
  data() {
    return {
      observer: null,
      analogInput: null,
      active: true,
      total: 0,
      portNumberString: '',
      dateFormat: null,
      componentKey: 0,
      firstrun: true,
      layout: {
        title: this.title
      },
      title: null,
      units: null,
      valueXField: 'date',
      chartSeries: [],
      chartNumberFormats: {
        // numberFormat: "#.00",
        numericFields: ["value"]
      }
    }
  },
  props: {
    limit: {
      type: Number,
      default: 500
    },
    unitID: String,
    nrtime: Boolean,
    description: Object,
    dateRange: Object,
    dateRangeFriendly: Object,
  },
  watch: {
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.chartDetails.root && this.getData()
      } else {
        this.chartDetails.root && this.getDataRange()
      }
    }
  },
  methods: {
    async getData() {
      const database = this.$store.getters.userDatabase;
      this.firstrun = true;

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.analogInput)
        .orderBy("MessageUTC", "desc")
        .where("MessageUTC", ">=", moment.utc().subtract(1, 'days').toISOString().replace("T", " ").slice(0, -5))
        .limit(this.limit)
        .get();

      this._onSnapshot(this.observer);
    },
    async getDataRange() {
      const database = this.$store.getters.userDatabase;

      this.firstrun = true;

      // Unsubscribe from snapshots for realtime data
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.analogInput)
        .orderBy("MessageUTC", "asc")
        .where("MessageUTC", ">=", this.dateRange.start)
        .where("MessageUTC", "<=", this.dateRange.end)
        // .limit(this.limit)
        .get();

      this._onSnapshot(this.observer, true)
    },
    _onSnapshot (querySnapshot, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return
      }

      // Setup tmp array to house our data for the first run
      const tmpData = [];

      querySnapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          let date = new Date(change.doc.data().MessageUTC + 'Z');
          let data = {
            date: date.getTime(),
            name: this.units,
            value: change.doc.data().Payload.temperature
          };
          if (this.firstrun) {
            // Order matters for real time vs. custom date range
            if (!this.nrtime) {
              tmpData.push(data)
            } else {
              // If first run (for real time data), add the data (in reverse order) to the data array
              tmpData.unshift(data)
            }
          } else {
            // If new snapshot, append data and remove last element (FIFO)
            this.addChartData(data)
          }
        }
      });

      // If this was the first run, set the chart data in bulk
      if (this.firstrun) {
        this.setChartData(tmpData)
      }

      // Flag first run as false for next function call
      this.firstrun = false;

      // Emit no longer loading
      this.$emit('loading', false)
    },

    isInViewport(waypointState) {
      if (waypointState.going === 'in') {
        // Disable waypoint observer after initial load
        this.active = false;

        // Listen for chart rendered events
        this.$on(
          'chartRendered', this.nrtime
            ? this.getData
            : this.getDataRange
        )

        // Render chart and get data
        this.renderChart()
      }
    }
  },
  mounted() {
    for (let property in this.description) {
      this.analogInput = property;
      this.title = this.description[property].description;
      this.units = this.description[property].units;
    }

    // Add the series
    this.chartSeries.push({
      name: this.units,
      field: "value",
      color: colors.colors.red
    })
  }
}

</script>
<style scoped lang="css">
  .card-amchart {
    width: 100%;
    height: 600px;
  }

  .chart-area {
    width: 100%;
    height: 450px;
  }

 </style>
