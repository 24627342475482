<template>
  <card card-body-classes="table-full-width">
    <template slot="header">
      <div class="d-flex items-start justify-content-between">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <base-button
          native-type="button" type="primary"
          :disabled="exportRunning || !canExport"
          @click="exportDataCSV"
        >
          {{ exportRunning ? 'Exporting...' : 'Export Data' }}
        </base-button>
      </div>
      <small v-if="nrtime" class="text-muted">
        Displaying results for the last 24hr
      </small>
      <small v-else class="">
        Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
      </small>
    </template>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <base-input>
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table
        :data="queriedData"
        :key="componentKey"
      >
        <el-table-column type="expand">
            <template slot-scope="props">
              <p
                v-for="(value, item) in props.row.payload"
                :key="item"
              >
                <b>{{ item }}:</b> {{ value }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
          :type="expand"
          sortable
        >
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import { meshDb } from "@/firebase";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import "firebase/functions";
import "firebase/storage";
import moment from 'moment/moment'
import exportMixin from "./Mixins/exportMixin";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mixins: [exportMixin],
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      observer: null,
      componentKey: 0,
      expand: null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: '',
      propsToSearch: ['eventID',
        'localTime',
        'timestamp',
        'event_type',
        'msg_type'
      ],
      tableColumns: [
        {
          prop: 'eventID',
          label: 'Event ID',
          minWidth: 225
        },
        {
          prop: 'localTime',
          label: 'Local Timestamp',
          minWidth: 250
        },
        {
          prop: 'timestamp',
          label: 'UTC Timestamp',
          minWidth: 200
        },
        {
          prop: 'event_type',
          label: 'Event Type',
          minWidth: 150
        },
        {
          prop: 'msg_type',
          label: 'Sub Type',
          minWidth: 200
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  props: {
    title: {
      type: String,
      default: 'All Events'
    },
    description: {
      type: String,
      default: ''
    },
    unitID: String,
    nrtime: Boolean,
    dateRange: Object,
    dateRangeFriendly: Object,
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
    queriedData() {
      this.componentKey += 1;
    },
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.getEvents()
      } else {
        this.getEventsRange()
      }
    }
  },
  methods: {
    async getEvents() {
      const database = this.$store.getters.userDatabase;
      this.tableData = []
      this.firstrun = true;

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("events")
        .orderBy("timestamp", "desc")
        .where("timestamp", ">=", moment.utc().subtract(1, 'days').toISOString().replace("T", " ").slice(0, -5))
        .limit(1000) // limit to 1000 results
        .get();

      this._onSnapshot(this.observer, true)

    },
    async getEventsRange() {
      const database = this.$store.getters.userDatabase;
      this.tableData = []
      this.firstrun = true;

      // Unsubscribe from snapshots for realtime data
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("events")
        .orderBy("timestamp", "desc")
        .where("timestamp", ">=", this.dateRange.start)
        .where("timestamp", "<=", this.dateRange.end)
        .limit(1000) // limit to 1000 results
        .get();

      this._onSnapshot(this.observer, true)
    },
    _onSnapshot (querySnapshot, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return
      }

      // Setup tmp array to house our data for the first run
      const tmpData = [ ...this.tableData ];
      this.dataToExport = [];

      // Loop through the data
      querySnapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          let eventID = { eventID: change.doc.id };
          let utcTime = new Date(change.doc.data().timestamp + "Z");
          let localTime = { localTime: utcTime.toLocaleString() };
          if (tmpData.length < 100) {
            tmpData.push({
              ...eventID,
              ...localTime,
              ...change.doc.data()
            });
          } else {
            tmpData.unshift({
              ...eventID,
              ...localTime,
              ...change.doc.data()
            });
          }
        }
      });

      // Set the table data
      this.tableData = tmpData;
      this.dataToExport = tmpData;

      // Flag first run as false for next function call
      this.firstrun = false;

      // Emit no longer loading
      this.$emit('loading', false)
    },
    getFileName() {
      let fileName = 'events';
      if (!this.nrtime) {
        const start = moment(this.dateRange.start).format(this.fileNameDateFormat);
        const end = moment(this.dateRange.end).format(this.fileNameDateFormat);
        fileName += `_${start}_${end}.csv`;
      } else {
        const date = moment().format(this.fileNameDateTimeFormat);
        fileName += `_${date}.csv`;
      }
      return fileName;
    },
  },
  created() {
    this.getEvents()
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['eventID',
        'localTime',
        'timestamp',
        'event_type',
        'msg_type'
      ],
      threshold: 0.3
    });
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>
