<template>
  <stats-card
    :title="title"
    :sub-title="subTitle"
    :type="type"
    :icon="icon"
  >
    <div v-if="footer" slot="footer" v-html="footer"></div>
  </stats-card>
</template>
<script>
import { meshDb } from "../../firebase.js";
import StatsCard from 'src/components/Cards/StatsCard';

export default {
  data() {
    return {
      observer: null,
      date: null,
      title: "",
      subTitle: "Updated:",
      type: "info",
      icon: "tim-icons icon-square-pin",
      footer: null,
      alertTime: 86400 * 1000, // 1 day in milliseconds
      alertUnits: "hours"
    }
  },
  components: {
    StatsCard
  },
  props: {
    unitID: null
  },
  methods: {
    async getUnit() {
      const database = this.$store.getters.userDatabase;
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .onSnapshot({
          includeMetadataChanges: true
        }, (doc) => {
          this.unitData = doc.data();
          this.date = new Date(doc.data().MessageUTC + "Z");
          this.title = this.date.toLocaleString();
          let now = Date.now();
          this.type = "info";
          if ((now - this.date) > this.alertTime) {
            this.type = "danger";
            this.title = "WARNING: " + this.title.toString();
            let hours = this.alertTime / 1000 / 3600
            this.footer = `<i class="tim-icons icon-calendar-60"></i></i> No update within ${hours} ${this.alertUnits}`
          }
        });
    }
  },
  created() {
    this.getUnit();
  },
  beforeDestroy() {
    this.observer();
    this.observer = null;
  }
}
</script>
