<template>
  <div>
    <h2 style="margin-bottom: 10px;">{{ identifier }}</h2>
    <div style="margin-bottom: 20px; display: flex;">
      <p v-if="showcpuID" style="padding-right: 20px;"><span style="font-weight: bold; flex-wrap: wrap;">ID: </span>{{ ID }}</p>
      <p><span style="font-weight: bold;"> Last Activity - </span> {{ difference }} </p>
    </div>
    <tabs type="primary">
      <tab-pane label="Overview">
        <div :style="gridStyle" class="grid-layout">
          <div class="stats-container">
            <h2 style="margin-bottom: 10px; margin-bottom: 20px;">Statistics</h2>
            <div class="scroll-container">
              <div v-if="showUnitStatus">
                <UnitStatus :unitID="unitID"></UnitStatus>
              </div>
              <div v-if="Object.keys(powerState).length">
                <stats-card
                  :title="powerState.title"
                  :sub-title="powerState.subTitle"
                  :type="powerState.type"
                  icon="tim-icons icon-button-power"
                >
                  <div slot="footer" v-html="powerState.footer"></div>
                </stats-card>
              </div>
              <div v-if="showNetworkStatus">
                <NetworkStatus :unitID="unitID"></NetworkStatus>
              </div>
              <template v-if="validDigitals.length">
                <div v-for="(value, name) in validDigitals" :key="`digital_card_${name}`">
                  <DigitalInput
                    :description="value"
                    :digitalInputNumber="name"
                    :unitID="unitID"
                  >
                  </DigitalInput>
                </div>
              </template>
              <div
                v-loading="exportProcessing"
                element-loading-background="rgba(255, 255, 255, 0.8)"
              >
                <ExportEvents :unitID="unitID" @loading="(loading) => exportProcessing = loading"></ExportEvents>
              </div>
            </div>
          </div>
          <div class="gauge-container">
            <template v-if="validAnalogs.length">
              <div v-for="(value, name) in validAnalogs" :key="`analog_card_${name}`" class="gauge">
                <AnalogInput
                  :description="value"
                  :analogInputNumber="name"
                  :unitID="unitID"
                >
                </AnalogInput>
              </div>
            </template>
          </div>
          <div class="map-container">
            <UnitMap :unitID="unitID" class="map"></UnitMap>
          </div>
          <div v-if="showDiag" class="diagnostics-container">
            <h2>Diagnostics</h2>
            <DiagnosticTable 
              :unitID="unitID"
              :columns="diagColumns"
            ></DiagnosticTable>
          </div>
          <div v-if="showEvents" class="events-container">
            <h2>Event Log</h2>
            <EventTable :unitID="unitID"></EventTable>
          </div>
        </div>
      </tab-pane>

      <tab-pane label="Events" v-if="validTables.length > 0">
        <div class="row">
          <div class="col-md-12" ml-auto mr-auto>
            <card class="card pb-3">
              <template slot="header">
                <h5 class="card-category">Range Selection max {{ maxEventsRange }} days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-calendar-60"></i> Filter Event Data
                </h3>
                <form @submit.prevent>
                  <div>
                    <base-input v-if="eventsNearRealTime">
                      <el-date-picker
                        v-model="eventsDateRange"
                        type="datetimerange"
                        start-placeholder="Start Date"
                        end-placeholder="End Date"
                        :picker-options="eventsPickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                    <div align="center">
                      <base-button
                        v-if="eventsNearRealTime"
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        @click="getEventsPowerDataRange"
                      >
                        Set Range
                      </base-button>
                      <base-button
                        v-else
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        :loading="chargeEventsLoading"
                        @click="eventsNearRealTime = true"
                      >
                        Back to Near Real Time View
                      </base-button>
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
          <div class="col-md-12" v-for="table in validTables" :key="table.source">
            <div
              v-if="table.source === 'Events/ChargeAndRunEvents'"
              v-loading="chargeEventsLoading"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <ChargeAndRunEvents
                :unitID="unitID"
                :title="table.title"
                :description="table.description"
                :nrtime="eventsNearRealTime"
                :date-range="eventsDataDateRange"
                :date-range-friendly="eventsDataDateRangeFriendly"
                @loading="(loading) => chargeEventsLoading = loading"
              ></ChargeAndRunEvents>
            </div>
          </div>
        </div>
      </tab-pane>

      <tab-pane label="Time Series" v-if="validTimeSeries.length > 0 || validApps.length > 0">
        <div class="row">
          <div class="col-md-12" ml-auto mr-auto>
            <card class="card pb-3">
              <template slot="header">
                <h5 class="card-category">Range Selection max {{ maxTsRange }} days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-calendar-60"></i> Filter Time Series Data
                </h3>
                <form @submit.prevent>
                  <div>
                    <base-input v-if="tsNearRealTime">
                      <el-date-picker
                        v-model="tsDateRange"
                        type="datetimerange"
                        start-placeholder="Start Date"
                        end-placeholder="End Date"
                        :picker-options="tsPickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                    <div align="center">
                      <base-button
                        v-if="tsNearRealTime"
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        @click="getTsPowerDataRange"
                      >
                        Set Range
                      </base-button>
                      <base-button
                        v-else
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        :loading="powerUsageLoading || timeSeriesLoading"
                        @click="tsNearRealTime = true"
                      >
                        Back to Near Real Time View
                      </base-button>
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
          <template v-if="validApps.length">
            <div
              class=col-md-12 ml-auto mr-auto
              v-for="app in validApps"
              :key="app.source"
            >
              <div
                v-if="app.appName === 'PowerUsage'"
                v-loading="powerUsageLoading"
                element-loading-background="rgba(255, 255, 255, 0.8)"
              >
                <PowerUsage
                  :unitID="unitID"
                  :collection="app.source"
                  :description="app.description"
                  :limit="5000"
                  :date-range="tsDataDateRange"
                  :date-range-friendly="tsDataDateRangeFriendly"
                  :nrtime="tsNearRealTime"
                  @loading="(loading) => powerUsageLoading = loading"
                >
                </PowerUsage>
              </div>
            </div>
          </template>
          <div
            v-if="validTimeSeries.length > 0"
            class=col-md-12
            ml-auto mr-auto
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <div class="row">
              <div
                class="col-md-4"
                v-for="(value, name) in validTimeSeries"
                :key="name"
              >
                <TimeSeries
                  :limit="1000"
                  :unitID="unitID"
                  :description="value"
                  :date-range="tsDataDateRange"
                  :date-range-friendly="tsDataDateRangeFriendly"
                  :nrtime="tsNearRealTime"
                  @loading="(loading) => timeSeriesLoading = loading"
                >
                </TimeSeries>
              </div>
            </div>
          </div>
        </div>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
import AnalogInput from '@/pages/Stats/AnalogInput.vue';
import UnitMap from '@/pages/Maps/UnitMap';
import ExportEvents from '@/pages/Events/ExportEvents';
import TimeSeries from "@/pages/Charts/TimeSeries";
import PowerUsage from '@/pages/Charts/Apps/PowerUsage';
import UnitStatus from '@/pages/Stats/UnitStatus';
import NetworkStatus from '@/pages/Stats/NetworkStatus';
import DigitalInput from '@/pages/Stats/DigitalInput';
import DiagnosticTable from '@/pages/Events/DiagnosticsTable.vue';
import EventTable from '@/pages/Events/TableWidget.vue';
import ChargeAndRunEvents from '@/pages/Events/CalculatedChargeAndRunEvents.vue';
import swal from 'sweetalert2';
import {DatePicker} from 'element-ui';
import moment from 'moment';
import StatsCard from 'src/components/Cards/StatsCard';
import { TabPane, Tabs } from 'src/components';
import { meshDb } from "@/firebase";

export default {
  components: {
    AnalogInput,
    UnitMap,
    ExportEvents,
    TimeSeries,
    PowerUsage,
    UnitStatus,
    NetworkStatus,
    DigitalInput,
    DiagnosticTable,
    EventTable,
    ChargeAndRunEvents,
    StatsCard,
    [DatePicker.name]: DatePicker,
    TabPane,
    Tabs
  },
  data() {
    return {
      dateFormat: 'M/D/YYYY, h:mm:ss A',
      difference: null,
      identifier: "",
      showcpuID: false,
      ID: null,

      exportProcessing: false,
      chargeEventsLoading: false,
      powerUsageLoading: false,
      timeSeriesLoading: false,

      maxTsRange: 30,
      tsDateRange: [],
      tsDataDateRange: {
        start: null,
        end: null
      },
      tsDataDateRangeFriendly: {
        start: null,
        end: null
      },
      tsNearRealTime: true, // default to "near real time" data
      tsPickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      },

      // Global event date range selection
      maxEventsRange: 90,
      eventsDateRange: [],
      eventsDataDateRange: {
        start: null,
        end: null
      },
      eventsDataDateRangeFriendly: {
        start: null,
        end: null
      },
      eventsNearRealTime: true, // default to "near real time" data
      eventsPickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      }
    }
  },
  props: {
    unitID: String,
    showUnitStatus: Boolean,
    showNetworkStatus: Boolean,
    showDiag: Boolean,
    diagColumns: Map,
    showEvents: Boolean,
    validAnalogs: Array,
    validDigitals: Array,
    validTables: Array,
    validApps: Array,
    validTimeSeries: Array,
    configuredAnalogs: Array,
    description: [Object, Array],
    powerState: Object,
  },
  methods: {
    /**
     * Time Series global date selection
     * @returns {Promise<void>}
    */

    gridStyle () {
      // return {
      //   'grid-template-areas': `"stats guage map map" "stats diag diag events"`
      // };
    },

    setTitle () {
      const entityID = this.$store.getters.userProfile.entityID;
      meshDb
        .collection('entities')
        .doc(entityID)
        .collection('hardware')
        .doc(this.unitID)
        .get().then((doc) => {
          const friendlyName = doc.get("friendlyName");
          if (friendlyName !== undefined && friendlyName !== "") {
            this.identifier = friendlyName;
            this.showcpuID = true;
            this.ID = this.unitID;
          } else {
            this.identifier = "ID: " + this.unitID;
          }
        }); 
    },

    async checkUnitStatus() {
      const database = this.$store.getters.userDatabase;
      const unsubscribe = meshDb
        .collection(database)
        .doc(this.unitID)
        .onSnapshot(docSnapshot => {
          const data = docSnapshot.data();
          const timestamp = new Date(data.ReceiveUTC + "Z");
          const currentDate = new Date();
          const timeDifference = currentDate - timestamp;

          // Less than 10 minutes = Current
          if (timeDifference < (10*60*1000)) {
            this.difference = "Current";
          } 
          
          // Less than 1 hour = incrament by 5 min
          else if (timeDifference < (60*60*1000)) {
            var minutes = timeDifference / (1000*60);
            this.difference = (Math.round(minutes/5)*5) + " minutes ago";
          } 
          
          // Less than 24 hours = incrament by hours
          else if (timeDifference < (24*60*60*1000)) {
            var hours = Math.round(timeDifference / (1000*60*60));
            if (hours > 1) {
              this.difference = hours + " hours ago";
            } else {
              this.difference = hours + " hour ago";
            }
          }

          // Less than 8 days = incrament by hours
          else if (timeDifference < (8*24*60*60*1000)) {
            var days = Math.round(timeDifference / (1000*60*60*24));
            if (days > 1) {
              this.difference = days + " days ago";
            } else {
              this.difference = days + " day ago";
            }
          }

          else{
            this.difference = timestamp.toLocaleDateString();
          }
        });

      // Call unsubscribe() when you want to stop listening to changes
      // unsubscribe();
    },

    async getTsPowerDataRange() {
      if (! (this.tsDateRange && this.tsDateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.tsDateRange[0]);
      let endDate = moment(this.tsDateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      if (numberOfDays > this.maxTsRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxTsRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      // Ensure valid date range selected
      const startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.tsDataDateRange = {start: startUTC, end: endUTC}
      this.tsDataDateRangeFriendly = {
        start: moment(this.tsDateRange[0]).format(this.dateFormat),
        end: moment(this.tsDateRange[1]).format(this.dateFormat),
      }
      this.tsNearRealTime = false
    },

    /**
     * Event global date selection
     * @returns {Promise<void>}
     */
    async getEventsPowerDataRange() {
      if (! (this.eventsDateRange && this.eventsDateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.eventsDateRange[0]);
      let endDate = moment(this.eventsDateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      // Ensure valid date range selected
      if (numberOfDays > this.maxEventsRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxEventsRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      const startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.eventsDataDateRange = {start: startUTC, end: endUTC}
      this.eventsDataDateRangeFriendly = {
        start: moment(this.eventsDateRange[0]).format(this.dateFormat),
        end: moment(this.eventsDateRange[1]).format(this.dateFormat),
      }
      this.eventsNearRealTime = false
    },
  },
  mounted() {
      this.checkUnitStatus();
      this.setTitle();
    }
};
</script>
<style>

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

  .grid-layout {
    display: grid;
    height: 80vh;
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: .75fr 1.25fr;
    gap: 20px;
    grid-template-areas: "gauge map" "stats map" "stats diag" "stats events";
  }

  .stats-container {
    /* grid-column: 1 / 2;
    grid-row: 1 / 3; */
    grid-area: stats;
    background-color: rgb(190, 190, 190);
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
  }

  .stats-container h1 {
    margin-bottom: 10px;
  }

  .scroll-container {
    overflow: auto;
    scrollbar-width: none;
    max-height: calc(100% - 100px);
    padding-left: 10px;
  }

  .gauge-container {
    grid-area: gauge;
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
  }

  .gauge {
    width: 40%;
  }

  .map-container {
    /* grid-column: 3 / 5;
    grid-row: 1 / 2; */
    grid-area: map;
    min-height: 800px;
  }

  .map {
    height: 100%;
  }

  .diagnostics-container {
    /* grid-column: 2 / 4;
    grid-row: 2 / 3; */
    grid-area: diag;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(190, 190, 190);
    height: fit-content;
  }

  .events-container {
    /* grid-column: 4 / 5;
    grid-row: 2 / 3; */
    grid-area: events;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(190, 190, 190);
    height: fit-content;
  }
</style>
