<template>
  <card card-body-classes="table-full-width">
    <template slot="header">
      <div class="d-flex items-start justify-content-between">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <div class="d-flex items-end justify-content-center">
          <base-button
            simple
            native-type="button"
            :loading="runningCalculations"
            :disabled="runningCalculations"
            @click="runEventCalculations"
          >
            {{ exportRunning ? 'Processing...' : 'Run Event Calculations' }}
          </base-button>
          <base-button
            class="mr-2"
            native-type="button" type="primary"
            :loading="exportRunning"
            :disabled="exportRunning || !canExport"
            @click="exportDataCSV(null, exportHeaders)"
          >
            {{ exportRunning ? 'Exporting...' : 'Export Data' }}
          </base-button>
        </div>
      </div>
      <small v-if="nrtime" class="text-muted">
        Displaying results for the last 24hr
      </small>
      <small v-else class="">
        Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
      </small>
    </template>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <base-input>
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table
        ref="multipleTable"
        :data="queriedData"
        :key="componentKey"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <p
              v-for="(value, item) in props.row.payload"
              :key="item"
            >
              <b>{{ item }}:</b> {{ value }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
          :type="expand"
          sortable
          :formatter="column.hasOwnProperty('formatter') ? column.formatter : null"
        >
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <base-button
          simple
          native-type="button"
          :disabled="!multipleSelection.length"
          @click="clearSelection()"
        >
          Clear selection
        </base-button>
        <base-button
          native-type="button"
          type="primary"
          :disabled="!multipleSelection.length"
          :loading="updatingEventTag"
          @click="promptForTagInput()"
        >
          Tag selection
        </base-button>
      </div>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn, Select, Option, MessageBox } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import {meshFunctions, meshDb} from "@/firebase"
import "firebase/storage";
import moment from 'moment';
import exportMixin from "./Mixins/exportMixin";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mixins: [exportMixin],
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      observer: null,
      componentKey: 0,
      expand: null,
      firstrun: true,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      multipleSelection: [],
      updatingEventTag: false,
      runningCalculations: false,
      searchQuery: '',
      propsToSearch: [
        'start',
        'startTimestamp',
        'end',
        'endTimestamp',
        'runtime',
        'type',
        'totalPower',
        'totalCurrent',
        'totalVoltage'
      ],
      exportHeaders: [
        'start',
        'updated',
        'runtime',
        'totalAmpHours',
        'totalKWH',
        'tag',
        'type',
        'payload'
      ],
      tableColumns: [
        {
          prop: 'start',
          label: 'Local Start Timestamp',
          minWidth: 250
        },
        {
          prop: 'updated',
          label: 'Local Updated Timestamp',
          minWidth: 250
        },
        {
          prop: 'runtime',
          label: 'Total Time',
          minWidth: 250
        },
        {
          prop: 'totalAmpHours',
          label: 'Total AmpHours',
          minWidth: 150,
          formatter: row => parseFloat(row.totalAmpHours).toFixed(4)
        },
        {
          prop: 'totalKWH',
          label: 'Total KWH',
          minWidth: 150,
          formatter: row => parseFloat(row.totalKWH).toFixed(4)
        },
        {
          prop: 'tag',
          label: 'Tag',
          minWidth: 150
        },
        {
          prop: 'type',
          label: 'Event Type',
          minWidth: 150
        },
      ],
      lastDataPoint: null,
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Run & Charge Events'
    },
    description: {
      type: String,
      default: ''
    },
    unitID: String,
    nrtime: Boolean,
    dateRange: Object,
    dateRangeFriendly: Object,
  },
  //props: ['unitID', 'nrtime', 'dateRange', 'dateRangeFriendly'],
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
    queriedData() {
      this.componentKey += 1;
    },
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.getEvents()
      } else {
        this.getEventsRange()
      }
    }
  },
  methods: {
    async getEvents() {
      const database = this.$store.getters.userDatabase;
      this.tableData = []
      this.firstrun = true;
      this.lastDataPoint = null;

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("powerUsageEvents")
        .orderBy("updateTimestamp", "desc")
        .where("updateTimestamp", ">=", moment.utc().subtract(1, 'days').toISOString().replace("T", " ").slice(0, -5))
        .onSnapshot(this._onSnapshot)
    },
    async getEventsRange() {
      const database = this.$store.getters.userDatabase;
      this.tableData = []
      this.firstrun = true;
      this.lastDataPoint = null;

      // Unsubscribe from snapshots for realtime data
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection("powerUsageEvents")
        .orderBy("updateTimestamp", "desc")
        .where("updateTimestamp", ">=", this.dateRange.start)
        .where("updateTimestamp", "<=", this.dateRange.end)
        .get();

      this._onSnapshot(this.observer, true)
    },
    _onSnapshot (querySnapshot, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return
      }

      // Setup tmp array to house our data for the first run
      const tmpData = [ ...this.tableData ];
      this.dataToExport = [];

      // Loop through the data
      querySnapshot.docChanges().forEach(change => {
        let data;
        if (force || change.type === "added" || change.type === "modified") {
          // Get local timestamps
          const start = (new Date(change.doc.data().startTimestamp + 'Z'))
            .toLocaleString()
          const updated = (new Date(change.doc.data().updateTimestamp + 'Z'))
            .toLocaleString()

          // Get the item data
          const docData = change.doc.data();
          data = {
            ...docData,
            id: change.doc.id,
            // Manually set tag, not populated by default
            tag: docData.tag || null,
            payload: {
              // Update the payload to include local timestamps
              ...docData.payload,
              startTimestamp: docData.startTimestamp,
              start,
              updated
            },
            start,
            updated
          }
        }
        if (force || change.type === "added") {
          // If added simply push onto the array
          tmpData.push(data);
        } else if (change.type === "modified") {
          // If modified, get the index of the updated event
          const updatedIndex = tmpData.findIndex(e => e.id === change.doc.id);
          if (updatedIndex !== -1) {
            // If event found, update its data!
            tmpData[updatedIndex] = data;
          }
        }
      });

      // Set the table data
      this.tableData = tmpData;
      this.dataToExport = [ ...tmpData ]; // use a shallow copy

      // Flag first run as false for next function call
      this.firstrun = false;

      // Emit no longer loading
      this.$emit('loading', false)
    },
    getFileName() {
      let fileName = 'run_charge_events';
      if (!this.nrtime) {
        const start = moment(this.dateRange.start).format(this.fileNameDateFormat);
        const end = moment(this.dateRange.end).format(this.fileNameDateFormat);
        fileName += `_${start}_${end}.csv`;
      } else {
        const date = moment().format(this.fileNameDateTimeFormat);
        fileName += `_${date}.csv`;
      }
      return fileName;
    },
    async updateEventTag(tag) {
      // Get the users DB
      const database = this.$store.getters.userDatabase;

      // Flag updating
      this.updatingEventTag = true;

      // Update the selected events
      if (this.multipleSelection.length) {
        // Update as a batch action
        const batch = meshDb.batch();
        this.multipleSelection.forEach((doc) => {
          let docRef;
          const docId = doc.id || null;
          if (docId) {
            docRef = meshDb
              .collection(database)
              .doc(this.unitID)
              .collection("powerUsageEvents")
              .doc(docId);
            batch.update(docRef, { tag })
          }
        });
        // Commit the changes
        await batch.commit();
      }

      // Flag updating complete
      this.updatingEventTag = false;

      // If not real time, re-fetch current set of data
      if (!this.nrtime) {
        this.getEventsRange();
      }

      // Clear the currently selected items
      this.clearSelection();
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    promptForTagInput() {
      // Make sure not still running another update
      if (this.updatingEventTag) {
        MessageBox(
          'The previous update is still running, please wait a moment and try again',
          'Updating', 'info'
        );
        return;
      }

      // Make sure items are selected
      if (!this.multipleSelection.length) {
        MessageBox(
          'Select some events to update their tags',
          'No Events Selected', 'info'
        );
        return;
      }

      // Get value and update it
      MessageBox.prompt('Please enter a value for the tag', 'Tag', {
        confirmButtonText: 'Update Now',
        cancelButtonText: 'Cancel',
        inputPattern: /^[a-zA-Z0-9_ ]+$/, // Alphanumeric only
        inputErrorMessage: 'Alphanumeric characters only'
      }).then(({ value }) => {
        MessageBox(
          'Updating tag to: ' + value,
          'Updated', 'success'
        );
        this.updateEventTag(value)
      }).catch(() => {/* ... */});
    },
    runEventCalculations() {
      // Flag as running
      this.runningCalculations = true;

      // Get a reference to the callable function
      const processRunAndChargeEvents = meshFunctions
        .httpsCallable("processRunAndChargeEvents");

      // Call the function with the data args
      processRunAndChargeEvents({
        database: this.$store.getters.userDatabase,
        unitID: this.unitID
      }).then((result) => {
          // ... result.data.VARIABLE
          this.runningCalculations = false;
          // If not real time, re-fetch current set of data
          if (!this.nrtime) {
            this.getEventsRange();
          }
          // Show success
          swal.fire({
            text: `Added ${result.data.eventCount} events, starting from ${result.data.from}. Additional processing may be needed.`,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-ok btn-fill"
            }
          });
        }).catch(err => {
          alert("Failed to run event calculations.");
          console.error(err);
          this.runningCalculations = false;
        });
    }
  },
  created() {
    this.getEvents()
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: [
        'start',
        'startTimestamp',
        'updated',
        'updateTimestamp',
        'runtime',
        'type',
        'totalPower',
        'totalCurrent',
        'totalVoltage'
      ],
      threshold: 0.3
    });
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>
