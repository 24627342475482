<template>
  <card class="card" v-loading="loading">
    <div slot="header" class="card-title">
      <h5 class="card-category">Range Selection max {{ maxRange }} days</h5>
      <template v-if="nrtime">
        <h3 class="card-title"><i class="tim-icons icon-map-big"></i> Current Location</h3>
      </template>
      <template v-else >
        <h3 class="card-title"><i class="tim-icons icon-map-big"></i> Location History</h3>
        <small class="">
          Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
        </small>
      </template>
      <form @submit.prevent>
        <div>
          <base-input v-if="nrtime">
            <el-date-picker
              v-model="dateRange"
              type="datetimerange"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </base-input>
          <div align="center">
            <base-button
              class="mt-3"
              native-type="submit"
              type="primary"
              v-if="canUpdateRange || (nrtime && dateRange.length === 2)"
              @click="setDateRange()"
            >
              View Range History
            </base-button>
            <base-button
              class="mt-3"
              native-type="submit"
              type="primary"
              v-if="!nrtime"
              @click="nrtime = true"
              :loading="loading"
            >
              Back to Current Location
            </base-button>
          </div>
        </div>
      </form>
    </div>
    <div id="satelliteMap" class="map"></div>
    <div slot="footer"></div>
  </card>
</template>
<script>
import {auth, meshDb} from "@/firebase"
import {DatePicker} from "element-ui"
import swal from "sweetalert2"
import moment from "moment/moment"
import {convertLocalToUTC} from "@/localToUTC"

export default {
  name: "Map",
  components: {
    [DatePicker.name]: DatePicker,
  },
  data () {
    return {
      center: null,
      loading: false,
      firstRun: true,
      observer: null,
      unitData: null,
      map: null,
      marker: null,
      infoWindow: null,
      nrtime: true,
      canUpdateRange: false,
      dateFormat: 'M/D/YYYY, h:mm:ss A',
      geoDateFormat: 'YYYY-MM-DD HH:mm:ss',
      dateRange: [],
      dataDateRange: [],
      polylineData: [],
      maxRange: 30,
      history: null,
      bounds: null,
      dateRangeFriendly: {
        start: null,
        end: null
      },
      pickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      },
    }
  },
  props: ['unitID'],
  watch: {
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.getUnit()
      }
    },
    dateRange: {
      handler: function (newRange, oldRange) {
        this.canUpdateRange = JSON.stringify(newRange) !== JSON.stringify(oldRange)
      },
      deep: true
    }
  },
  methods: {
    async getUnit () {
      const database = this.$store.getters.userDatabase
      this.firstRun = true
      this.polylineData = []

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {
      }

      // If polyline set, clear it
      if (this.history) {
        try {
          this.history.setMap(null)
          this.history = null
        } catch (e) {}
      }

      // Flag fetching as true
      this.$emit('loading', true);
      this.loading = true;

      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection('simpleReport')
        .orderBy("MessageUTC", "desc")
        .limit(1)
        .onSnapshot(this._onSnapshot)
    },
    async getUnitRange () {
      // Flag fetching as true
      this.$emit('loading', true);
      this.loading = true;

      // If polyline set, clear it
      if (this.history) {
        try {
          this.history.setMap(null)
          this.history = null
        } catch (e) {}
      }

      // Format the dates as the function expects
      const localStartTime = moment(this.dateRange[0]).format(this.geoDateFormat);
      const localEndTime = moment(this.dateRange[1]).format(this.geoDateFormat);

      const [startTime, endTime] = convertLocalToUTC(localStartTime, localEndTime);

      // const url = `https://us-central1-mesh-portal-stg.cloudfunctions.net/geo_tracer?cpuid=${this.unitID}&startTime=${startTime}&endTime=${endTime}&`;
      const token = await auth.currentUser.getIdToken()
      const response = await fetch(
        "https://us-central1-mesh-portal-stg.cloudfunctions.net/geo_tracer",
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          },
          method: "post",
          body: JSON.stringify({
            cpuid: this.unitID,
            startTime,
            endTime
          })
        }
      );
      if (response.ok) {
        const json = await response.json();

        if (!(json.coordinates && json.coordinates.length)) {
          swal.fire({
            text: "No results found for selected range.",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-ok btn-fill"
            }
          });
        } else {
          // Get the coordinates
          this.polylineData = json.coordinates || [];
          // Hide the marker
          this.hideMarker();
          // Create the Poly
          this.createPolyLine();
        }
      } else {
        const error = await response.text();
        console.warn('ERROR', error, 'response', response);
      }
      this.$emit('loading', false);
      this.loading = false;
    },
    _onSnapshot (querySnapshot, withHistory = false, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return null
      }

      // Loop through the data
      let lastDataPoint = null;
      querySnapshot.docChanges().forEach(change => {
        if (force || change.type === "added") {
          const doc = change.doc
          const {
            latitude,
            longitude
          } = doc.data().Payload
          const data = doc.data()
          if (withHistory) {
            // Note: simply lib expects x,y. Swap to lat,lng after simplifying points
            this.polylineData.push({
              x: latitude,
              y: longitude
            })
          }
          lastDataPoint = {
            latitude,
            longitude,
            id: data.MobileID,
            data
          }
        }
      })

      // If we have a data point, set/update the marker
      if (lastDataPoint) {
        // Get the last data point properties
        const { latitude, longitude, id, data } = lastDataPoint
        // Update the unit data
        this.unitData = data
        // Update map center
        const latLng = new google.maps.LatLng(latitude, longitude)
        this.center = latLng

        // Get marker data
        const updated = moment.utc(data["MessageUTC"]);
        const tenMinAgo = moment.utc().subtract(10, 'minutes');
        const diff = tenMinAgo.diff(updated, 'hours');
        const status = diff > 0 ? 'red' : 'green'; // if updated more than 24hr ago, flag as red, else green

        // If marker set, update it, else add it
        if (this.marker) {
          this.updateMarker(latLng, data)
        } else {
          this.createMarker(latLng, status, data)
          // Pan map to the latest location
          this.map.panTo(latLng)
          // ...and zoom
          this.map.setZoom(18)
        }
      }

      // See if we need to draw a polyline
      if (this.polylineData.length) {
        this.createPolyLine()
      }

      // Flag first run as false for subsequent function call
      this.firstrun = false;

      // Flag fetching as complete
      this.loading = false;
      this.$emit('loading',  false);
    },
    /**
     * Event date selection
     * @returns {Promise<void>}
     */
    async setDateRange() {
      if (! (this.dateRange && this.dateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.dateRange[0]);
      let endDate = moment(this.dateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      // Ensure valid date range selected
      if (numberOfDays > this.maxRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      const startUTC = startDate.utc()
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate.utc()
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.dataDateRange = {start: startUTC, end: endUTC}
      this.dateRangeFriendly = {
        start: moment(this.dateRange[0]).format(this.dateFormat),
        end: moment(this.dateRange[1]).format(this.dateFormat),
      }

      // Disable re-applying range, unless changed
      this.canUpdateRange = false

      // Flag as no longer real time
      this.nrtime = false

      // Fetch results
      this.getUnitRange()
    },
    createPolyLine () {
      // Debug
      console.log('=== Generating Polyline ===')

      // If polyline set, clear it
      if (this.history) {
        try {
          this.history.setMap(null)
          this.history = null
        } catch (e) {}
      }

      // Info: https://developers.google.com/maps/documentation/javascript/examples/polyline-simple
      console.log('Adding poly data to the map')
      this.history = new google.maps.Polyline({
        path: this.polylineData,
        geodesic: false,
        strokeColor: "#000dff",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.history.setMap(this.map);

      this.bounds = new google.maps.LatLngBounds();
      for (var i=0; i < this.polylineData.length; i++) {
        this.bounds.extend(new google.maps.LatLng(this.polylineData[i].lat, this.polylineData[i].lng));
      }
      this.map.fitBounds(this.bounds);
    },
    hideMarker () {
      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }
    },
    createMarker (latLng, color, data = null) {
      let infoWindow;
      if (data) {
        const updated = moment.utc(data['MesageUTC']).local().format(this.dateFormat)
        infoWindow = new google.maps.InfoWindow({
          content: `<h5>Current location for <strong>${this.unitID}</strong></h5><small style="color: #383838">Updated: ${updated}</small>`
        })
      } else {
        infoWindow = new google.maps.InfoWindow({
          content: `<h5><strong>${this.unitID}</strong></h5>`
        })
      }

      const marker = new google.maps.Marker({
        position: latLng,
        title: this.unitID,
        icon: this.getMarkerIcon(color)
      })
      marker.addListener("click", () => infoWindow.open(this.map, marker))
      marker.setMap(this.map)

      this.infoWindow = infoWindow
      this.marker = marker
    },
    getMarkerIcon(type) {
      const c1 = type === 'green' ? "#5cea35" : "#EA4335";
      const c2 = type === 'green' ? "#12a60d" : "#A60D0D";
      const size = 36;
      const svg = `<svg height="${size}" width="${size}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 86.96 122.88" xml:space="preserve"><g>
                     <path fill-rule="evenodd" clip-rule="evenodd" fill="${c1}" d="M27.81,96.57c4.42,7.04,9.2,15.57,13.92,23.86c1.75,3.06,3.33,3.52,5.43-0.11c4.56-7.91,9.04-16.1,13.76-23.74 c14.33-23.19,37.78-45.8,19.13-77.01C63.49-8.14,18.45-5.18,4.98,20.62C-10.46,50.19,13.65,74.03,27.81,96.57L27.81,96.57z"/>
                     <path fill-rule="evenodd" clip-rule="evenodd" fill="${c2}" d="M43.46,25.59c9.31,0,16.86,7.55,16.86,16.86s-7.55,16.86-16.86,16.86c-9.31,0-16.86-7.55-16.86-16.86 S34.15,25.59,43.46,25.59L43.46,25.59z"/>
                   </g></svg>`;

      return {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
        anchor: new google.maps.Point(size / 2, size)
      }
    },
    updateMarker (latLng, data = null) {
      this.marker.setPosition(latLng)
      if (data) {
        const updated = moment.utc(data['MessageUTC']).local().format(this.dateFormat)
        this.infoWindow.setContent(`<h5>Current location for <strong>${this.unitID}</strong></h5><small style="color: #383838">Updated: ${updated}</small>`)
      } else {
        this.infoWindow.setContent(`<h5>${this.unitID}</h5>`)
      }
    },
    setupMap () {
      // Set up the map
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById("satelliteMap"), {
          zoom: 15,
          scrollwheel: false,
          center: this.center,
          mapTypeId: google.maps.MapTypeId.SATELLITE
        })
      }

      // Fetch the latest data point
      this.getUnit()
    },
  },
  mounted() {
    // Set up the map
    this.setupMap()
  },
  beforeDestroy () {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  }
}
</script>
<style lang="scss">
.card-map {
  min-height: 350px;
  .map {
    height: 100%;
    width: 100%;
  }
}
</style>
