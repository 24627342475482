 <template>
  <stats-card
    :title="title"
    :sub-titles="subTitles"
    :type="type"
    :icon="icon"
  >
    <div slot="footer" v-html="footer"></div>
  </stats-card>
</template>
<script>
import { meshDb } from "../../firebase.js";
import StatsCard from 'src/components/Cards/StatsCard';

export default {
  data() {
    return {
      observer: null,
      date: null,
      title: "",
      type: "info",
      icon: "tim-icons icon-button-power",
      footer: '',
      digitalInputNumber: null,
      specifications: null,
      subTitles: []
    }
  },
  components: {
    StatsCard
  },
  props: {
    unitID: null,
    description: null
  },
  methods: {
    async getUnit() {
      const database = this.$store.getters.userDatabase;
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.digitalInputNumber)
        .orderBy("MessageUTC", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              let value = "";
              let unit = "";
              this.subTitles.length = 0;
              for (let spec in this.specifications) {
                unit = this.specifications[spec]['unit'];
                value = change.doc.data().Payload[this.specifications[spec]['field']];
                if (unit === 'bytes') {
                  let size = 0;
                  if (value >= 1099511627776){
                    size = value / 1099511627776;
                    unit = "TB";
                    value = parseFloat(size.toFixed(3));
                  } else if (value >= 1073741824){
                      size = value / 1073741824;
                      unit = "GB";
                      value = parseFloat(size.toFixed(3));
                  } else if (value >= 1048576){
                      size = value / 1048576;
                      unit = "MB";
                      value = parseFloat(size.toFixed(3));
                  } else if (value >= 1024){
                      size = value / 1024;
                      unit = "KB"
                      value = parseFloat(size.toFixed(3));
                  }                   
                }
                this.subTitles.push({
                  name: this.specifications[spec]['name'],
                  value: value,
                  unit: unit
                });
              }
              this.date = new Date(change.doc.data().MessageUTC + "Z");
              var currentDate = new Date()
              this.footer = `<i class="tim-icons icon-calendar-60"></i></i> Updated: ${this.date.toLocaleString()}`;
              if ((currentDate - this.date) > (10 * 60 * 1000)) {
                this.type = "warning";
              }
              else {
                this.type = "primary"
              }
            }
          });
        });
    }
  },
  mounted() {
    this.getUnit();
  },
  created() {
    for (let property in this.description) {
      this.digitalInputNumber = property
      this.specifications = this.description[property].specifications,
      this.title = this.description[property].description
    }
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  }
}
</script>
