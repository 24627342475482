import {Parser} from "json2csv/lib/json2csv";
import moment from "moment";

export default {
  name: 'exportMixin',

  data() {
    return {
      dataToExport: [],
      fileNameDateFormat: 'YYYY-M-D-H',
      fileNameDateTimeFormat: 'YYYY-M-D-H_mm_ss',
      exportRunning: false,
    }
  },

  computed: {
    canExport() {
      return this.dataToExport.length
    }
  },

  methods: {
    /**
     * Generate file name
     * Override to customize file name
     *
     * @returns {string}
     */
    getFileName() {
      const date = moment().format(this.fileNameDateTimeFormat)
      return `export_${date}.csv`
    },

    /**
     * Generate CSV from data
     *
     * @param name {string|null}
     * @param headers {array|null}
     * @returns {Promise<boolean>}
     */
    async exportDataCSV(name = null, headers = null) {
      if (!this.dataToExport.length) {
        console.log("No records were found for that date range!");
        return false;
      }

      let fileName;
      if (name !== null && name.length) {
        fileName = name;
      } else {
        fileName = this.getFileName();
      }

      this.exportRunning = true;
      let csv = null;
      try {
        let opts = {}
        if (Array.isArray(headers)) {
          opts = {
            fields: headers
          }
        }
        const json2csvParser = new Parser(opts);
        csv = json2csvParser.parse(this.dataToExport);

        // Creating a Blob for having a csv file format
        // and passing the data with type
        const blob = new Blob([csv], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob);

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a');

        // Passing the blob downloading url
        a.setAttribute('href', url);

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', fileName);

        // Performing a download with click
        a.click();

        // Destroy the object
        window.URL.revokeObjectURL(url);

        this.exportRunning = false;
        return true;
      } catch (e) {
        console.error("Error processing CSV:", e);
        this.exportRunning = false;
        return false;
      }
    }
  }
}
