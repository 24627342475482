function convertLocalToUTC(startTime, endTime) {

    // Create a new Date objects from the local date and time strings
    const localStartTime = new Date(startTime);
    const localEndTime = new Date(endTime);

    // Get the UTC date and time string with toISOString() methoud
    const utcStartDateTime = localStartTime.toISOString();
    const utcEndDateTime = localEndTime.toISOString();

    // Extract the UTC date and time components and combine them in
    //   the proper format
    const utcStartDate = utcStartDateTime.slice(0, 10);
    const utcStartTime = utcStartDateTime.slice(11, 19);
    const utcStart = utcStartDate + ' ' + utcStartTime;

    const utcEndDate = utcEndDateTime.slice(0, 10);
    const utcEndTime = utcEndDateTime.slice(11, 19);
    const utcEnd = utcEndDate + ' ' + utcEndTime;

    return [utcStart, utcEnd];
}

export { convertLocalToUTC };