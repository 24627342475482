<template>
  <div class="row">
    <div class="col-md-12">
      <base-button
        round
        native-type="button"
        type="primary"
        class="mb-3"
        @click="$router.push(`/dashboard/${unitID}`)"
      >
        <i class="tim-icons icon-double-left"></i>
        Unit Dashboard
      </base-button>
    </div>
      <div class="col-md-12" ml-auto mr-auto>
        <card class="card pb-3">
          <template slot="header">
            <h5 class="card-category">Range Selection max {{ maxEventsRange }} days</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-calendar-60"></i> Filter Event Data
            </h3>
            <form @submit.prevent>
              <div>
                <base-input v-if="eventsNearRealTime">
                  <el-date-picker
                    v-model="eventsDateRange"
                    type="datetimerange"
                    start-placeholder="Start Date"
                    end-placeholder="End Date"
                    :picker-options="eventsPickerOptions"
                  >
                  </el-date-picker>
                </base-input>
                <div align="center">
                  <base-button
                    class="mt-3"
                    native-type="submit"
                    type="primary"
                    v-if="eventsNearRealTime"
                    @click="getEventsPowerDataRange"
                  >
                    Set Range
                  </base-button>
                  <base-button
                    class="mt-3"
                    native-type="submit"
                    type="primary"
                    v-if="!eventsNearRealTime"
                    @click="eventsNearRealTime = true"
                  >
                    Back to Near Real Time View
                  </base-button>
                </div>
              </div>
            </form>
          </template>
        </card>
      </div>
      <div
        class="col-md-12"
        v-loading="allEventsLoading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      >
        <EventTable
          :unitID="unitID"
          :nrtime="eventsNearRealTime"
          :date-range="eventsDataDateRange"
          :date-range-friendly="eventsDataDateRangeFriendly"
          @loading="(loading) => allEventsLoading = loading"
        ></EventTable>
      </div>
    </div>
</template>
<script>
import EventTable from '@/pages/Events/EventTable';
import swal from 'sweetalert2';
import {DatePicker} from 'element-ui';
import moment from 'moment';

export default {
  components: {
    EventTable,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      unitID: null,
      dateFormat: 'M/D/YYYY, h:mm:ss A',
      allEventsLoading: false,
      exportProcessing: false,
      maxEventsRange: 7,
      eventsDateRange: [],
      eventsDataDateRange: {
        start: null,
        end: null
      },
      eventsDataDateRangeFriendly: {
        start: null,
        end: null
      },
      eventsNearRealTime: true, // default to "near real time" data
      eventsPickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      }
    }
  },
  methods: {
    /**
     * Event global date selection
     * @returns {Promise<void>}
     */
    async getEventsPowerDataRange() {
      if (! (this.eventsDateRange && this.eventsDateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.eventsDateRange[0]);
      let endDate = moment(this.eventsDateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      // Ensure valid date range selected
      if (numberOfDays > this.maxEventsRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxEventsRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      const startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.eventsDataDateRange = {start: startUTC, end: endUTC}
      this.eventsDataDateRangeFriendly = {
        start: moment(this.eventsDateRange[0]).format(this.dateFormat),
        end: moment(this.eventsDateRange[1]).format(this.dateFormat),
      }
      this.eventsNearRealTime = false
    },
  },
  created() {
    this.unitID = this.$route.params.unitID
  }
};
</script>
