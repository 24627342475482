<template>
  <card class="card-amchart">
    <template slot="header">
      <h5 class="card-category">{{ title }}</h5>
      <h3 class="card-title">
        <i class="tim-icons icon-atom"></i> {{ description }}
      </h3>
      <small v-if="nrtime" class="text-muted">
        Displaying realtime results
      </small>
      <small v-else class="">
        Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
      </small>
    </template>
    <div ref="chartmenudiv"></div>
    <div
      class="chart-area"
      ref="chartdiv"
      componentKey="componentKey"></div>
    <div class="chart-legend" ref="chartlegenddiv"></div>
  </card>
</template>

<script>
import colors from "@/config.js"

import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import amc5mixin from '@/pages/Charts/Mixins/amc5mixin'

import {bus} from "@/util/bus"
import {meshDb} from "@/firebase"

export default {
  mixins: [amc5mixin],

  data() {
    return {
      observer: null,
      total: 0,
      portNumberString: '',
      dateFormat: null,
      componentKey: 0,
      firstrun: true,
      showLegend: true,
      layout: {
        title: this.portNumberString
      },
      pickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      },
      valueXField: 'date',
      chartSeries: [
        {
          name: "Battery Capacity (AH)",
          field: "ampHours",
          color: colors.colors.orange
        },
        {
          name: "Battery Capacity (WH)",
          field: "wattHours",
          color: colors.colors.teal
        },
        {
          name: "Average Power (Watts)",
          field: "avgPower",
          color: colors.colors.red
        },
        {
          name: "Average Current (Amps)",
          field: "avgCurrent",
          color: colors.colors.blue
        },
        {
          name: "Average Voltage (Volts)",
          field: "avgVoltage",
          color: colors.colors.gray
        }
      ],
      chartNumberFormats: {
        numberFormat: "#.####",
        numericFields: [
          "ampHours",
          "wattHours",
          "avgPower",
          "avgCurrent",
          "avgVoltage"
        ]
      }
    }
  },
  components: {},
  props: {
    title: {
      type: String,
      default: 'Power Usage App'
    },
    description: {
      type: String,
      default: 'Power Consumption Over Time'
    },
    collection: {
      type: String,
      default: 'powerUsageReport'
    },
    limit: {
      type: Number,
      default: 1000
    },
    yAxisLabel: {
      type: String,
      default: ''
    },
    unitID: String,
    nrtime: Boolean,
    dateRange: Object,
    dateRangeFriendly: Object,
  },
  watch: {
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.chartDetails.root && this.getPowerData()
      } else {
        this.chartDetails.root && this.getPowerDataRange()
      }
    }
  },
  methods: {
    async getPowerData() {
      const database = this.$store.getters.userDatabase;
      const yesterday = new Date(Date.now() - (86400 * 1000));
      this.firstrun = true;

      // Make sure observer isn't currently set
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.collection)
        .orderBy("MessageUTC", "desc")
        .where("MessageUTC", ">=", yesterday.toISOString().replace("T", " ").slice(0, -5))
        .limit(this.limit)
        .onSnapshot(this._onSnapshot);
    },
    async getPowerDataRange() {
      const database = this.$store.getters.userDatabase;
      this.firstrun = true;

      // Unsubscribe from snapshots for realtime data
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.collection)
        .orderBy("MessageUTC", "asc")
        .where("MessageUTC", ">=", this.dateRange.start)
        .where("MessageUTC", "<=", this.dateRange.end)
        .limit(this.limit)
        .get();

      this._onSnapshot(this.observer, true)
    },
    _onSnapshot (querySnapshot, force = false) {
      // If not real time (or passing `force` flag) return
      if (!(this.nrtime || force)) {
        return
      }

      // Setup tmp array to house our data for the first run
      const tmpData = [];

      // Loop through the data
      querySnapshot.docChanges().forEach(change => {
        if (force || change.type === "added") {
          const date = new Date(change.doc.data().MessageUTC + 'Z');
          const data = {
            date: date.getTime(),
            ampHours: change.doc.data().Payload.ampHours,
            wattHours: change.doc.data().Payload.wattHours,
            avgPower: change.doc.data().Payload.averagePower,
            avgCurrent: change.doc.data().Payload.averageCurrent,
            avgVoltage: change.doc.data().Payload.averageVoltage
          }
          if (this.firstrun) {
            // Order matters for real time vs. custom date range
            if (!this.nrtime) {
              tmpData.push(data)
            } else {
              // If first run (for real time data), add the data (in reverse order) to the data array
              tmpData.unshift(data)
            }
          } else {
            // If new snapshot, append data and remove last element (FIFO)
            this.addChartData(data)
          }
        }
      });

      // If this was the first run, set the chart data in bulk
      if (this.firstrun) {
        this.setChartData(tmpData)
      }

      // Flag first run as false for next function call
      this.firstrun = false;

      // Emit no longer loading
      this.$emit('loading', false)
    },
  },
  mounted() {
    // Listen for chart rendered events
    this.$on('chartRendered', this.getPowerData)

    // Render chart and get data
    this.renderChart();
  }
}

</script>
<style scoped lang="css">
  .card-amchart {
    width: 100%;
    height: 800px;
  }

  .chart-area {
    width: 100%;
    height: 650px;
  }

  .chart-legend {
    width: 100%;
    height: 100px;
  }

 </style>
