<template>
  <div>
    <tabs type="primary">
      <tab-pane label="Overview">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <UnitStatus :unitID="unitID"></UnitStatus>
          </div>
          <div v-if="Object.keys(powerState).length" class="col-lg-3 col-md-6">
            <stats-card
              :title="powerState.title"
              :sub-title="powerState.subTitle"
              :type="powerState.type"
              icon="tim-icons icon-button-power"
            >
              <div slot="footer" v-html="powerState.footer"></div>
            </stats-card>
          </div>
          <template v-if="validAnalogs.length">
            <div v-for="(value, name) in validAnalogs" :key="`analog_card_${name}`" class="col-lg-3 col-md-6">
              <AnalogInput
                :description="value"
                :analogInputNumber="name"
                :unitID="unitID"
              >
              </AnalogInput>
            </div>
          </template>
          <template v-if="validDigitals.length">
            <div v-for="(value, name) in validDigitals" :key="`digital_card_${name}`" class="col-lg-3 col-md-6">
              <DigitalInput
                :description="value"
                :digitalInputNumber="name"
                :unitID="unitID"
              >
              </DigitalInput>
            </div>
          </template>
          <div
            class="col-md-3 auto-ml mr-auto"
            v-loading="exportProcessing"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <ExportEvents :unitID="unitID" @loading="(loading) => exportProcessing = loading"></ExportEvents>
          </div>
          <div class="col-md-12">
            <UnitMap :unitID="unitID"></UnitMap>
          </div>
        </div>
      </tab-pane>

      <tab-pane label="Events">
        <div class="row">
          <div class="col-md-12" ml-auto mr-auto>
            <card class="card pb-3">
              <template slot="header">
                <h5 class="card-category">Range Selection max {{ maxEventsRange }} days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-calendar-60"></i> Filter Event Data
                </h3>
                <form @submit.prevent>
                  <div>
                    <base-input v-if="eventsNearRealTime">
                      <el-date-picker
                        v-model="eventsDateRange"
                        type="datetimerange"
                        start-placeholder="Start Date"
                        end-placeholder="End Date"
                        :picker-options="eventsPickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                    <div align="center">
                      <base-button
                        v-if="eventsNearRealTime"
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        @click="getEventsPowerDataRange"
                      >
                        Set Range
                      </base-button>
                      <base-button
                        v-else
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        :loading="chargeEventsLoading"
                        @click="eventsNearRealTime = true"
                      >
                        Back to Near Real Time View
                      </base-button>
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
          <div
            class="col-md-12"
            v-loading="chargeEventsLoading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <ChargeAndRunEvents
              :unitID="unitID"
              :nrtime="eventsNearRealTime"
              :date-range="eventsDataDateRange"
              :date-range-friendly="eventsDataDateRangeFriendly"
              @loading="(loading) => chargeEventsLoading = loading"
            ></ChargeAndRunEvents>
          </div>
        </div>
      </tab-pane>

      <tab-pane label="Time Series">
        <div class="row">
          <div class="col-md-12" ml-auto mr-auto>
            <card class="card pb-3">
              <template slot="header">
                <h5 class="card-category">Range Selection max {{ maxTsRange }} days</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-calendar-60"></i> Filter Time Series Data
                </h3>
                <form @submit.prevent>
                  <div>
                    <base-input v-if="tsNearRealTime">
                      <el-date-picker
                        v-model="tsDateRange"
                        type="datetimerange"
                        start-placeholder="Start Date"
                        end-placeholder="End Date"
                        :picker-options="tsPickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                    <div align="center">
                      <base-button
                        v-if="tsNearRealTime"
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        @click="getTsPowerDataRange"
                      >
                        Set Range
                      </base-button>
                      <base-button
                        v-else
                        class="mt-3"
                        native-type="submit"
                        type="primary"
                        :loading="powerUsageLoading || timeSeriesLoading || tempTimeSeriesLoading"
                        @click="tsNearRealTime = true"
                      >
                        Back to Near Real Time View
                      </base-button>
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
          <div
            class=col-md-12
            ml-auto mr-auto
            v-loading="powerUsageLoading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <PowerUsage
              :unitID="unitID"
              :limit="5000"
              :date-range="tsDataDateRange"
              :date-range-friendly="tsDataDateRangeFriendly"
              :nrtime="tsNearRealTime"
              @loading="(loading) => powerUsageLoading = loading"
            >
            </PowerUsage>
          </div>
          <div
            class=col-md-12
            ml-auto mr-auto
            v-loading="timeSeriesLoading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <TimeSeries
              v-for="(value, name) in validAnalogs"
              v-bind:key="name"
              :limit="1000"
              :unitID="unitID"
              :description="value"
              :date-range="tsDataDateRange"
              :date-range-friendly="tsDataDateRangeFriendly"
              :nrtime="tsNearRealTime"
              @loading="(loading) => timeSeriesLoading = loading"
            >
            </TimeSeries>
          </div>
          <template v-if="validTemperatures.length">
            <div
              class=col-md-12
              ml-auto mr-auto
              v-loading="tempTimeSeriesLoading"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <TempTimeSeries
                v-for="(value, name) in validTemperatures"
                v-bind:key="name"
                :limit="1000"
                :unitID="unitID"
                :description="value"
                :date-range="tsDataDateRange"
                :date-range-friendly="tsDataDateRangeFriendly"
                :nrtime="tsNearRealTime"
                @loading="(loading) => tempTimeSeriesLoading = loading"
              >
              </TempTimeSeries>
            </div>
          </template>
        </div>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
import AnalogInput from '@/pages/Stats/AnalogInput.vue';
import UnitMap from '@/pages/Maps/UnitMap';
import ExportEvents from '@/pages/Events/ExportEvents';
import TimeSeries from "@/pages/Charts/TimeSeries";
import TempTimeSeries from "@/pages/Charts/TempTimeSeries";
import PowerUsage from '@/pages/Charts/Apps/PowerUsage';
import UnitStatus from '@/pages/Stats/UnitStatus';
import DigitalInput from '@/pages/Stats/DigitalInput';
import ChargeAndRunEvents from '@/pages/Events/CalculatedChargeAndRunEvents.vue';
import swal from 'sweetalert2';
import {DatePicker} from 'element-ui';
import moment from 'moment';
import StatsCard from 'src/components/Cards/StatsCard';
import { TabPane, Tabs } from 'src/components';

export default {
  components: {
    AnalogInput,
    UnitMap,
    ExportEvents,
    TimeSeries,
    TempTimeSeries,
    PowerUsage,
    UnitStatus,
    DigitalInput,
    ChargeAndRunEvents,
    StatsCard,
    [DatePicker.name]: DatePicker,
    TabPane,
    Tabs
  },
  data() {
    return {
      dateFormat: 'M/D/YYYY, h:mm:ss A',

      exportProcessing: false,
      chargeEventsLoading: false,
      allEventsLoading: false,
      powerUsageLoading: false,
      timeSeriesLoading: false,
      tempTimeSeriesLoading: false,

      maxTsRange: 30,
      tsDateRange: [],
      tsDataDateRange: {
        start: null,
        end: null
      },
      tsDataDateRangeFriendly: {
        start: null,
        end: null
      },
      tsNearRealTime: true, // default to "near real time" data
      tsPickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      },

      // Global event date range selection
      maxEventsRange: 90,
      eventsDateRange: [],
      eventsDataDateRange: {
        start: null,
        end: null
      },
      eventsDataDateRangeFriendly: {
        start: null,
        end: null
      },
      eventsNearRealTime: true, // default to "near real time" data
      eventsPickerOptions: {
        disabledDate (date) {
          return date > new Date();
        }
      }
    }
  },
  props: {
    unitID: String,
    validAnalogs: Array,
    validDigitals: Array,
    validTemperatures: Array,
    configuredAnalogs: Array,
    description: [Object, Array],
    powerState: Object,
  },
  methods: {
    /**
     * Time Series global date selection
     * @returns {Promise<void>}
     */
    async getTsPowerDataRange() {
      if (! (this.tsDateRange && this.tsDateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.tsDateRange[0]);
      let endDate = moment(this.tsDateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      if (numberOfDays > this.maxTsRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxTsRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      // Ensure valid date range selected
      const startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.tsDataDateRange = {start: startUTC, end: endUTC}
      this.tsDataDateRangeFriendly = {
        start: moment(this.tsDateRange[0]).format(this.dateFormat),
        end: moment(this.tsDateRange[1]).format(this.dateFormat),
      }
      this.tsNearRealTime = false
    },

    /**
     * Event global date selection
     * @returns {Promise<void>}
     */
    async getEventsPowerDataRange() {
      if (! (this.eventsDateRange && this.eventsDateRange.length === 2)) {
        swal.fire({
          text: "Please select a date range.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      let startDate = moment(this.eventsDateRange[0]);
      let endDate = moment(this.eventsDateRange[1]);
      let numberOfDays = endDate.diff(startDate, 'days');

      // Ensure valid date range selected
      if (numberOfDays > this.maxEventsRange) {
        swal.fire({
          text: `Please select a date range that contains ${this.maxEventsRange} days or less.`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-ok btn-fill"
          }
        });
        return null;
      }

      const startUTC = startDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");
      const endUTC = endDate
        .toISOString()
        .replace(/T/, " ")
        .replace(/000Z/, "");

      // Set the formatted date for components to use, and flag as no longer "real time" data
      this.eventsDataDateRange = {start: startUTC, end: endUTC}
      this.eventsDataDateRangeFriendly = {
        start: moment(this.eventsDateRange[0]).format(this.dateFormat),
        end: moment(this.eventsDateRange[1]).format(this.dateFormat),
      }
      this.eventsNearRealTime = false
    },
  }
};
</script>
<style></style>
