<template>
  <div>
    <card
      v-if="units === 'Percent'"
      class="card-stats"
      :show-footer-line="true"
    >
      <div class="row">
        <div class="col-12">
          <div class="w-full mx-auto">
            <v-gauge
              class="gauge-canvas"
              :value="value"
              unit="%"
              :options="gaugeOptions"
              height="300"
              width="600"
            />
          </div>
        </div>
        <div class="col-12" v-if="title || subTitle">
          <div class="numbers">
            <p v-if="subTitle" class="card-category">{{ subTitle }} &mdash; {{ title }}%</p>
          </div>
        </div>
      </div>
      <div class="stats" slot="footer" v-html="footer"></div>
    </card>
    <stats-card
      v-else
      :title="title"
      :sub-title="subTitle"
      :type="type"
      :icon="icon"
    >
      <div slot="footer" v-html="footer"></div>
    </stats-card>
  </div>
</template>
<script>
import { meshDb } from "@/firebase";
import Card from "src/components/Cards/Card";
import StatsCard from "src/components/Cards/StatsCard";
// Info: https://github.com/amroessam/vgauge
import VGauge from "vgauge";

export default {
  data() {
    return {
      observer: null,
      date: null,
      title: "",
      value: 0,
      type: "info",
      icon: "tim-icons icon-sound-wave",
      footer: '',
      analogInputNumber: null,
      subTitle: null,
      units: null,
      field: null,
      gaugeOptions: {
        // Info: http://bernii.github.io/gauge.js/#!
        angle: 0.05, // set to 0 for no angle
        generateGradient: true,
        highDpiSupport: true,
        percentColors: [[0.0, "#ff0000" ], [0.50, "#f9c802"], [1.0, "#a9d70b"]],
        pointer: {
          length: 0.6, // // Relative to gauge radius
          strokeWidth: 0.035, // The thickness
          color: '#383838' // Fill color
        },
        renderTicks: {
          divisions: 5,
          divWidth: 1.1,
          divLength: 0.6,
          divColor: "#ffffff",
          subDivisions: 4,
          subLength: 0.4,
          subWidth: 0.6,
          subColor: "#efefef"
        }
      }
    }
  },
  components: {
    Card,
    StatsCard,
    VGauge
  },
  props: {
    unitID: null,
    description: null
  },
  methods: {
    async getUnit() {
      const database = this.$store.getters.userDatabase;
      this.observer = await meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.analogInputNumber)
        .orderBy("MessageUTC", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              const value = parseFloat(change.doc.data().Payload[this.field]);
              this.value = value;
              this.title = value.toFixed(2); // clean up for title
              this.date = new Date(change.doc.data().MessageUTC + "Z");
              this.footer = `<i class="tim-icons icon-calendar-60"></i></i> Updated: ${this.date.toLocaleString()}`;
            }
          });
        });
    }
  },
  mounted() {
    this.getUnit();
  },
  created() {
    for (let property in this.description) {
      this.analogInputNumber = property
      this.subTitle = this.description[property].description
      this.units = this.description[property].units
      this.field = this.description[property].field

      // if (this.units !== 'Percent') {
      //   this.subTitle += ` (${this.units})`;
      // }
    }
  },
  beforeDestroy() {
    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  }
}
</script>

<style>
.gauge-canvas canvas {
  width: 100% !important;
  height: auto !important;
}
.gauge-title {
  display: none !important;
}
</style>
