<template>
    <div class="diagnosticsTable">
        <table>
            <thead>
            <tr>
                <th v-for="column in columns" :key="column.name">{{ column.name }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in diagnostics" :key="index">
                <td v-for="column in columns" :key="column.field">{{ item[column.name] }}</td>
            </tr>
            </tbody>
        </table>
    </div>
  </template>
  
  <script>
  import { meshDb } from "../../firebase.js";

  export default {
    data() {
      return {
        diagnostics: [],
        tableSize: 10,
        // columns: [
        //     {name:"Time Stamp", field:"MessageUTC"},
        //     {name:"Name", field:"Payload.name"},
        //     {name:"Source", field:"Payload.source"},
        //     {name:"State", field:"Payload.state"},
        //     {name:"Description", field:"Payload.description"},
        // ],
        source: "diagnostics"
      };
    },
    props: {
        unitID: null,
        columns: null,
    //     source: string
    },
    methods: {
        async getEvents() {
            const database = this.$store.getters.userDatabase;

            if (this.observer) {
                this.observer();
                this.observer = null;
            }

            this.observer = await meshDb
                .collection(database)
                .doc(this.unitID)
                .collection(this.source)
                .orderBy("MessageUTC", "desc")
                .limit(this.tableSize)
                .onSnapshot(querySnapshot => {
                    querySnapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            const newData = change.doc.data();
                            const newRow = {}
                            this.columns.forEach(column => {
                                const field = column.field;
                                let value = field.split('.').reduce((acc, part) => acc && acc[part], newData)
                                if (column.name === "Time Stamp") {
                                  const date = new Date(value + 'Z');
                                  value = date.toLocaleString(undefined, {
                                    hour12: true,
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                  });
                                } else if (column.name === "State") {
                                  if (value === 1) {
                                    value = "Active";
                                  } else {
                                    value = "Cleared";
                                  }
                                }
                                newRow[column.name] = value;
                                
                            });
                            if (this.diagnostics.length < this.tableSize) {
                              this.diagnostics.push(newRow);
                            } else {
                              this.diagnostics.unshift(newRow);
                              this.diagnostics.pop();
                            }
                        }
                    });
                });
        },
    },
    mounted() {
        this.getEvents();
    },
  };
</script>

<style>
.diagnosticsTable {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.diagnosticsTable table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.diagnosticsTable th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Light grey line for each row */
  color: black;
}

.diagnosticsTable th {
  background-color: rgb(63, 121, 168);
  color: white;
}

.diagnosticsTable tr:nth-child(even) {
  background-color: lightgray;
  color: black;
}

/* Responsive table */
@media screen and (max-width: 600px) {
  .diagnosticsTable table {
    display: block;
    overflow-x: auto;
  }
}
</style>
  