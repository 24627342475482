<template>
  <div v-loading="loading"
    :class="['smoothTransition', { 'expanded': expanded}]"
    @dblclick="toggleChart"
    class="chart-container">
    <card class="card-amchart" v-waypoint="{ active, callback: isInViewport }">
      <template slot="header">
        <h5 class="card-category">Source: {{ analogInput }}</h5>
        <h3 class="card-title">
          <i class="tim-icons icon-chart-bar-32"></i> {{ title }}
        </h3>
        <div v-if="nrtime && !overload" class="text-muted">
          <label for="time-select" style="margin-right: 10px;">Time Range: </label>
          <select id="time-select" v-model="hours" @change="refreshChart">
            <option value="24">24 hr</option>
            <option value="12">12 hr</option>
            <option value="1">1 hr</option>
            <option value="0.5">30 min</option>
          </select>
        </div>
        <!-- <small v-if="nrtime && !overload" class="text-muted">
          Displaying results for the last 24hr
        </small> -->
        <small v-else-if="overload" class="red-bold">
          DATA OVERLOAD: Due to a large amount of data, only {{ dateRangeFriendly.start }} to {{ lastDateRetrieved }} could be retrieved.
        </small>
        <small v-else class="">
          Displaying results from {{ dateRangeFriendly.start }} to {{ dateRangeFriendly.end }}
        </small>
      </template>
      <div ref="chartmenudiv"></div>
      <div
        class="chart-area"
        ref="chartdiv"
        componentKey="componentKey"
      ></div>
      <div class="chart-legend" ref="chartlegenddiv"></div>
    </card>
  </div>
</template>

<script>
import moment from 'moment'
import colors from "@/config.js"

import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import amc5mixin from '@/pages/Charts/Mixins/amc5mixin'

import {meshDb} from "@/firebase"

export default {
  mixins: [amc5mixin],
  data() {
    return {
      expanded: false,
      hours: 24,
      observer: null,
      analogInput: null,
      active: true,
      loading: false,
      total: 0,
      dateFormat: null,
      componentKey: 0,
      firstrun: true,
      showLegend: true,
      overload: false,
      lastDateRetrieved: null,
      layout: {
        title: this.title
      },
      title: null,
      valueXField: 'date',
      chartSeries: [],
      chartStyle: {
        width: '100%',
        height: '600px',
        position: 'relative',
        top: 'auto',
        left: 'auto',
        zIndex: 'auto',
        border: '0px',
        marginBottom: '20px',
      },
      chartNumberFormats: {
        // numberFormat: "#.##",
        numericFields: ["value"]
      }
    }
  },
  props: {
    batchSize: {
      type: Number,
      default: 500
    },
    sizeLimit: {
      type: Number,
      default: 15000
    },
    unitID: String,
    nrtime: Boolean,
    description: Object,
    dateRange: Object,
    dateRangeFriendly: Object,
  },
  watch: {
    nrtime: function (isRealTime) {
      if (isRealTime) {
        this.chartDetails.root && this.getData()
      } else {
        this.chartDetails.root && this.getDataRange()
      }
    }
  },
  methods: {
    toggleChart() {
      this.expanded = !this.expanded;
    },

    refreshChart() {
      this.refresh();
      this.getData();
    },
    async getData() {
      const database = this.$store.getters.userDatabase;
      this.firstrun = true;

      this.$emit('loading', true)

      // Reference documents within the wanted time range
      const query = meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.analogInput)
        .orderBy("MessageUTC", "desc")
        .where("MessageUTC", ">=", moment.utc().subtract(this.hours, 'hours').toISOString().replace("T", " ").slice(0, -5));
      
      // Start Process of grabbing documents in batches
      let lastDoc = null;
      let mergedDocs = [];
      while (true) {
        let currentBatch;

        // Grab the first or next batch
        if (lastDoc) {
          currentBatch = await query.startAfter(lastDoc).limit(this.batchSize).get();
        } else {
          currentBatch = await query.limit(this.batchSize).get();
        }

        // Start the next batch from where we left off.
        lastDoc = currentBatch.docs[currentBatch.docs.length - 1];

        currentBatch.forEach(doc => {
          let date = new Date(doc.data().MessageUTC + 'Z');
          let data = {
            date: date.getTime(),
            name: this.units,
          }
          for (let index in this.chartSeries) {
            let field = this.chartSeries[index].field;
            if (field === "null") {
              data[field] = 25;
            } else {
              data[field] = doc.data().Payload[field];
            }
          }
          mergedDocs.unshift(data)
        });

        // If the currentBatch size is small, were done.
        if (currentBatch.size < this.batchSize) {
          this.setChartData(mergedDocs);
          this.$emit('loading', false);
          break;
        }
      }

      const subscription = await meshDb
      .collection(database)
      .doc(this.unitID)
      .collection(this.analogInput)
      .orderBy("MessageUTC", "desc")
      .where("MessageUTC", ">", moment.utc().toISOString().replace("T", " ").slice(0, -5))
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            let date = new Date(change.doc.data().MessageUTC + 'Z');
            let data = {
              date: date.getTime(),
              name: this.units,
            }
            for (let index in this.chartSeries) {
              let field = this.chartSeries[index].field;
              if (field === "null") {
                data[field] = 25;
              } else {
                data[field] = doc.data().Payload[field];
              }
            }
            this.addChartData(data, this.hours);
          }
        });
      });
    },
    async getDataRange() {
      const database = this.$store.getters.userDatabase;
      this.firstrun = true;

      // Unsubscribe from snapshots for realtime data
      try {
        this.observer()
        this.observer = null
      } catch (e) {}

      this.$emit('loading', true)

      const query = meshDb
        .collection(database)
        .doc(this.unitID)
        .collection(this.analogInput)
        .orderBy("MessageUTC", "asc")
        .where("MessageUTC", ">=", this.dateRange.start)
        .where("MessageUTC", "<=", this.dateRange.end);

      // Start Process of grabbing documents in batches
      let lastDoc = null;
      let mergedDocs = [];
      while (true) {
        let currentBatch;

        // Grab the first or next batch
        if (lastDoc) {
          currentBatch = await query.startAfter(lastDoc).limit(this.batchSize).get();
        } else {
          currentBatch = await query.limit(this.batchSize).get();
        }

        // Start the next batch from where we left off.
        lastDoc = currentBatch.docs[currentBatch.docs.length - 1];

        currentBatch.forEach(doc => {
          let date = new Date(doc.data().MessageUTC + 'Z');
          let data = {
            date: date.getTime(),
            name: this.units,
          }
          for (let index in this.chartSeries) {
            let field = this.chartSeries[index].field;
            data[field] = doc.data().Payload[field];
          }
          mergedDocs.push(data)
        });

        // If the currentBatch size is small, were done.
        if (currentBatch.size < this.batchSize) {
          this.setChartData(mergedDocs);
          this.$emit('loading', false);
          break;
        }
      }
    },
    utcToLocal (utcDate) {
      // Convert UTC date string to a Date object
      const dateObj = new Date(utcDate);

      // Get the offset time in minutes
      const offsetMinutes = dateObj.getTimezoneOffset();

      // Convert UTC time to local time.
      dateObj.setMinutes(dateObj.getMinutes() - offsetMinutes)

      // Get local date and time components from the Date Object
      let options = {
        month: 'numeric',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        //timeZone: 'local',
      };

      let localDateTime = dateObj.toLocaleString('en-US', options);

      return localDateTime

    },
    isInViewport(waypointState) {
      if (waypointState.going === 'in') {
        // Disable waypoint observer after initial load
        this.active = false;

        // Listen for chart rendered events
        this.$on(
          'chartRendered', this.nrtime
            ? this.getData
            : this.getDataRange
        )

        // Render chart and get data
        this.renderChart()
      }
    }
  },
  mounted() {
    for (let property in this.description) {
      this.analogInput = property;
      this.title = this.description[property].description;
      this.chartSeries = this.description[property].chartSeries;
    }

    // Listen for loading events
    this.$on('loading', (loading) => this.loading = loading)

  }
}

</script>
<style scoped lang="css">

  .smoothTransition {
    /* transition: width 0.5s, height 0.5s; */
    width: 100%;
    height: 600px;
    position: relative;
    z-index: auto;
    border: 0px;
    margin-bottom: 20px;
  }

  .smoothTransition.expanded {
    width: 90%;
    height: 90%;
    position: fixed;
    top: 8%;
    left: 6%;
    z-index: 1000;
    border: 2px solid black;
    /* transition: width 0.5s, height 0.5s; */
  }

  .card-amchart {
    width: 100%;
    height: 100%;
  }

  .chart-area {
    width: 100%;
    height: 100%;
  }

  .chart-legend {
    width: 100%;
    height: 100px;
  }

  .red-bold {
    color:red;
    font-weight: bold;
  }

 </style>
