import { bus } from "@/util/bus";
import { mapGetters } from "vuex";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { first } from "@amcharts/amcharts5/.internal/core/util/Array";

const chartDefaults = {
  root: null,
  chart: null,
  export: null,
  xaxis: null,
  yaxis: null
}

export default {
  data() {
    return {
      noDataLabel: null,
      firstrun: true,
      showLegend: false,
      legendAdded: false,
      observer: null,
      valueXField: "date",
      chartDateFormat: "h:mm a '–' MMM dd, yyyy",
      chartDetails: { ...chartDefaults },
      chartSeries: [],
      chartNumberFormats: {},
    }
  },

  methods: {
    /**
     * Build the chart
     */
    renderChart() {
      // Make sure we have a ref
      if (!this.$refs.chartdiv) {
        console.error('"chartdiv" ref not set on component')
      }

      /*
       * Set the root element and attach a chart to it
       */

      // Create the root container
      this.chartDetails.root = am5.Root.new(this.$refs.chartdiv, {
        utc: true,
        tooltipContainerBounds: {
          top: 50,
          right: 100,
          bottom: 50,
          left: 100
        }
      });
      this.chartDetails.root._logo?.dispose();

      // Set the base theme
      this.chartDetails.root.setThemes([
        this.darkMode
          ? am5themes_Dark.new(this.chartDetails.root)
          : am5themes_Material.new(this.chartDetails.root)
      ]);

      // Create the chart
      this.chartDetails.chart = this.chartDetails.root.container.children.push(
        am5xy.XYChart.new(this.chartDetails.root, {
          panY: false,
          // wheelY: "zoomX",
          layout: this.chartDetails.root.verticalLayout,
          maxTooltipDistance: -1
        })
      );

      // Set global chart formatting
      this.chartDetails.root.numberFormatter.setAll(this.chartNumberFormats);
      this.chartDetails.root.dateFormatter.set("dateFormat", this.chartDateFormat);

      /*
       * Create the chart axis
       */

      // Yaxis
      this.chartDetails.yaxis = this.chartDetails.chart.yAxes.push(
        am5xy.ValueAxis.new(this.chartDetails.root, {
          // title: this.yAxisLabel
          renderer: am5xy.AxisRendererY.new(this.chartDetails.root, {
            minWidth: 35,
            minGridDistance: 20
          })
        })
      );
      const yaxisTooltip = am5.Tooltip.new(this.chartDetails.root, { themeTags: ["axis"] });
      yaxisTooltip.get("background").setAll({
        text: "[bold]{valueY}[/]",
        fill: am5.color(0xeeeeee)
      });
      this.chartDetails.yaxis.set("tooltip", yaxisTooltip);

      // Xaxis
      this.chartDetails.xaxis = this.chartDetails.chart.xAxes.push(
        am5xy.DateAxis.new(this.chartDetails.root, {
          renderer: am5xy.AxisRendererX.new(this.chartDetails.root, {}),
          baseInterval: {
            timeUnit: "minute",
            count: 1
          }
        })
      );
      const xaxisTooltip = am5.Tooltip.new(this.chartDetails.root, { themeTags: ["axis"] });
      xaxisTooltip.get("background").setAll({
        text: "[bold]{valueX}[/]",
        fill: am5.color(0xeeeeee)
      });
      this.chartDetails.xaxis.set("tooltip", xaxisTooltip);

      /*
       * Finish setting up chart details
       *
       * 1. Series
       * 2. Scrollbar
       * 3. Cursor
       */

      // Add chart series
      this.chartSeries.forEach(series => {
        this.addChartSeries(series.name, series.field, series.color);
      })

      // Add chart scrollbar
      this.addHorizontalChartScrollbar();

      // Add chart cursor
      this.chartDetails.chart.set("cursor", am5xy.XYCursor.new(this.chartDetails.root, {
        behavior: "zoomX",
      }));

      // Fetch the data
      this.$emit('chartRendered');
    },

    /**
     * Add scrollbar to the chart
     */
    addHorizontalChartScrollbar() {
      // Make sure chart is set
      if (!this.chartDetails.root) {
        return;
      }

      // Add chart scrollbar
      const scrollbar = am5.Scrollbar.new(this.chartDetails.root, {
        orientation: "horizontal"
      });
      const tooltip = am5.Tooltip.new(this.chartDetails.root, {});
      tooltip.get("background").setAll({ fill: am5.color(0xeeeeee) });
      scrollbar.set("tooltip", tooltip);
      scrollbar.startGrip.set("tooltipText", "Drag to zoom");
      scrollbar.endGrip.set("tooltipText", "Drag to zoom");
      this.chartDetails.chart.set("scrollbarX", scrollbar);
    },

    /**
     * Add series to chart
     *
     * @param name
     * @param field
     * @param color
     * @returns {*}
     */
    addChartSeries(name, field, color) {
      // Create the line series
      const series = this.chartDetails.chart.series.push(
        am5xy.LineSeries.new(this.chartDetails.root, {
          name,
          xAxis: this.chartDetails.xaxis,
          yAxis: this.chartDetails.yaxis,
          valueYField: field,
          valueXField: this.valueXField,
          fill: am5.color(color),
          stroke: am5.color(color)
        })
      );

      // Set up the tooltip
      const chartTooltip = series.set("tooltip", am5.Tooltip.new(this.chartDetails.root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        labelText: "[bold]{valueX.formatDate()}[/]",
      }));
      chartTooltip.get("background").setAll({ fill: am5.color(0xffffff) });
      chartTooltip.label.setAll({ fill: am5.color(0x000000) });
      chartTooltip.label.adapters.add("text", (text, target) => {
        this.chartDetails.chart.series.each((series) => {
          text += '\n[' + series.get("stroke").toString() + ']●[/] [bold width:100px]' + series.get("name") + ':[/] {' + series.get("valueYField") + '}'
        })
        return text
      })

      // Adjust the line settings
      series.strokes.template.setAll({ strokeWidth: 2 });

      // Return the series
      return series;
    },

    /**
     * Add new item to the chart
     *
     * @param data
     * @param hours
     */
    addChartData(data, hours) {
      // Add data to series
      this.chartDetails.chart.series.each((series) => {
        // Convert series.data to a standard array
        const dataArray = [];
        for (let i = 0; i < series.data.length; i++) {
          dataArray.push(series.data.getIndex(i));
        }

        // Find the correct index to insert the new data, starting from the end
        let insertIndex = dataArray.length;
        const newDataDate = new Date(data.date);
        for (let i = dataArray.length - 1; i >= 0; i--) {
          const currentDataDate = new Date(dataArray[i].date);
          if (newDataDate >= currentDataDate) {
            insertIndex = i + 1;
            break;
          }
        }

        // Insert the new data at the correct position
        dataArray.splice(insertIndex, 0, data);

        // Update the series data with the new array
        series.data.clear();
        dataArray.forEach(item => series.data.push(item));

        const firstDate = new Date(series.data.getIndex(0).date);
        const lastDate = new Date(series.data.getIndex(series.data.length - 1).date);
        const timeDiff = lastDate - firstDate

        if (timeDiff >= hours * 60 * 60 * 1000) {
          series.data.removeIndex(0)
        }
      });

      this.updateNoDataLabel()
    },

    /**
     * Set the chart data (will replace any current data, if set)
     *
     * @param data
     */
    setChartData(data) {
      // Update Xaxis and series data
      this.chartDetails.xaxis.setAll(data);
      this.chartDetails.chart.series.each((series) => series.data.setAll(data));

      this.updateNoDataLabel();

      // Create chart export menu
      if (this.$refs.chartmenudiv) {
        this.chartDetails.export?.dispose()
        this.chartDetails.export = am5plugins_exporting.Exporting.new(this.chartDetails.root, {
          menu: am5plugins_exporting.ExportingMenu.new(this.chartDetails.root, {
            container: this.$refs.chartmenudiv
          }),
          dataSource: data,
          dateFields: ["date"],
          // ...this.chartNumberFormats
        });
      }

      // Create chart legend
      if (this.showLegend && !this.legendAdded) {
        const legend = this.chartDetails.chart.children.push(
          am5.Legend.new(this.chartDetails.root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            useDefaultMarker: true,
            layout: am5.GridLayout.new(this.chartDetails.root, {
              maxColumns: 3,
              fixedWidthGrid: true
            })
          })
        );
        legend.markers.template.setAll({ width: 18, height: 18 });
        legend.data.setAll(this.chartDetails.chart.series.values);

        // Make sure we don't add additional legends
        this.legendAdded = true;
      }
    },

    updateNoDataLabel() {
      // Remove existing "No Data" label if it exists
      if (this.noDataLabel) {
        this.noDataLabel.dispose();
        this.noDataLabel = null;

        // Add chart cursor
        this.chartDetails.chart.set("cursor", am5xy.XYCursor.new(this.chartDetails.root, {
          behavior: "zoomX",
        }));
      }

      // Check if there is no data
      if (this.chartDetails.chart.series.values.some(series => series.data.length === 0)) {
        // Create and position the "No Data" label
        this.noDataLabel = this.chartDetails.chart.plotContainer.children.push(am5.Label.new(this.chartDetails.root, {
          text: "No Data",
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 40,
          fill: am5.color(0xD3D3D3) // Change the color if needed
        }));
        
        // Disable the cursor
        this.chartDetails.chart.set("cursor", null);
      }
    },

    /**
     * Cleanup the chart and reset to defaults
     */
    destroyChart() {
      if (this.chartDetails.root) {
        this.chartDetails.root.dispose();
      }
      this.chartDetails = { ...chartDefaults };
    },

    /**
     * Rebuild chart
     */
    refresh() {
      // Remove the chart
      this.destroyChart();

      // Unsubscribe from snapshot updates
      try { this.observer && this.observer() } catch (e) {}

      // Reset variables
      this.firstrun = true;
      this.legendAdded = false;

      // Render chart and get data
      this.renderChart();
    }
  },

  created () {
    // Listen for chart refresh events
    bus.$on('chartRefresh', () => this.refresh());

    // Listen for color mode changes
    bus.$on('darkModeToggled', (enabled) => {
      // Set the base theme
      if (this.chartDetails.root) {
        this.chartDetails.root.setThemes([])
        this.chartDetails.root.setThemes([
          enabled
            ? am5themes_Dark.new(this.chartDetails.root)
            : am5themes_Material.new(this.chartDetails.root)
        ])

        // Reinitialize chart scrollbar
        this.addHorizontalChartScrollbar();
      }
    });
  },

  beforeDestroy() {
    // Remove the chart
    this.destroyChart();

    // Unsubscribe from snapshot updates
    try { this.observer() } catch (e) {}
  },

  computed: {
    ...mapGetters({
      darkMode: 'darkMode'
    })
  }
}
