<template>
    <stats-card
      :title="title"
      :sub-titles="subTitles"
      :type="type"
      :icon="icon"
    >
      <div slot="footer" v-html="footer"></div>
    </stats-card>
  </template>
  <script>
  import { meshDb } from "../../firebase.js";
  import StatsCard from 'src/components/Cards/StatsCard';
  
  export default {
    data() {
      return {
        observer: null,
        date: null,
        title: "Network Status",
        type: "info",
        icon: "tim-icons icon-button-power",
        footer: '',
        digitalInputNumber: "status",
        specifications: [
            { field: "wifi_connection", name: "WIFI connected" },
            { field: "operator", name: "Celular Provider" },
            { field: "rsrp", name: "rsrp", unit: "dBm" },
            { field: "rsrq", name: "rsrq", unit: "dBm" },
            { field: "rssi", name: "rsssi", unit: "dBm" },
            { field: "sinr", name: "sinr", unit: "db" }
        ],
        specificationsSolec: [
            { field: "rsrp", name: "rsrp", unit: "dBm" },
            { field: "rsrq", name: "rsrq", unit: "dBm" },
            { field: "sinr", name: "sinr", unit: "db" }
        ],
        specificationsSolec2: [
            { field: "network", name: "Celular Provider" },
            { field: "rssi", name: "rssi", unit: "dBm" },
        ],
        subTitles: []
      }
    },
    components: {
      StatsCard
    },
    props: {
      unitID: null,
      description: null
    },
    methods: {
      async getUnit() {
        const database = this.$store.getters.userDatabase;
        if (database === "db-solectrac" || database === "db-wyeast") {
            this.observer = await meshDb
            .collection(database)
            .doc(this.unitID)
            .collection("network")
            .orderBy("MessageUTC", "desc")
            .limit(1)
            .onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                    let value = "";
                    this.subTitles.length = 0;
                    for (let spec of this.specificationsSolec2) {
                        value = change.doc.data()[spec.field];
                        if (value !== null) {
                            this.subTitles.push({
                                name: spec.name,
                                value: value,
                                unit: spec.unit
                            });
                        }
                    }
                    for (let spec of this.specificationsSolec) {
                        value = change.doc.data().Payload[spec.field];
                        if (value !== null) {
                            this.subTitles.push({
                                name: spec.name,
                                value: value,
                                unit: spec.unit
                            });
                        }
                    }
                    this.date = new Date(change.doc.data().MessageUTC + "Z");
                    var currentDate = new Date();
                    if ((currentDate - this.date) > (10 * 60 * 1000)) {
                        this.type = "warning";
                    }
                    else {
                        this.type = "primary"
                    }
                    this.footer = `<i class="tim-icons icon-calendar-60"></i></i> Updated: ${this.date.toLocaleString()}`;
                }
                });
            });
        } else {
            this.observer = await meshDb
            .collection(database)
            .doc(this.unitID)
            .collection(this.digitalInputNumber)
            .orderBy("MessageUTC", "desc")
            .limit(1)
            .onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                    let value = "";
                    this.subTitles.length = 0;
                    for (let spec of this.specifications) {
                        value = change.doc.data().Payload[spec.field];
                        if (typeof value === "string") {
                            value = value.replace("FloLive", "");
                        }
                        this.subTitles.push({
                            name: spec.name,
                            value: value,
                            unit: spec.unit
                        });
                    }
                    this.date = new Date(change.doc.data().MessageUTC + "Z");
                    var currentDate = new Date();
                    if ((currentDate - this.date) > (10 * 60 * 1000)) {
                        this.type = "warning";
                        this.subTitles[0].value = false;
                    }
                    else {
                        this.type = "primary"
                    }
                    this.footer = `<i class="tim-icons icon-calendar-60"></i></i> Updated: ${this.date.toLocaleString()}`;
                }
                });
            });
        }
      },
    },
    mounted() {
      this.getUnit();
    },
    beforeDestroy() {
      // Unsubscribe from snapshot updates
      try { this.observer() } catch (e) {}
    }
  }
  </script>